"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: '赤城自然園eTicket',
    organizationId: 'akAfjqFD0yrz8dt9jkq3',
    version: '1.0.0',
    ionicAppId: '',
    applicationKey: 'akagishizenen',
    applicationType: 'standalone',
    artistId: 'dK2VbUm4QobwY8P66TjzVMNndlq2',
    languages: ['ja', 'en'],
    ios: {
        bundleId: '',
        appId: '',
    },
    android: {
        bundleId: '',
    },
    platform: ['web'],
    custom: {
        modules: {
            tutorial: false,
            trade: false,
            membership: false,
            thread: true,
            prepaidPoint: {
                chargeable: true,
            },
            gacha: false,
            nft: false,
            liveStream: false,
            item: true,
            store: true,
            campaign: false,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: true,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: [],
        artistTabs: ['home', 'ownedItems', 'threads', 'notifications', 'myProfile'],
    },
    deeplinkUrl: 'https://akagishizenen.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/3562308',
    storagePath: 'akagishizenen',
    dynamicLinkPrefix: 'akagishizenen',
    deeplinkProd: 'akagishizenen.utoniq.com',
};
exports.default = appConfig;
