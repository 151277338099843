import '../app/bootstrap'

import { jaJP, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ErrorBoundary } from '@sentry/react'
import ja from 'dayjs/locale/ja'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { ApolloProvider, useApolloClient } from '@/clients/utoniq-core'
import {
  AppThemeProvider,
  ArtistThemeContextProvider,
  StyleProvider,
} from '@/components/styles'
import { config } from '@/config'
import {
  AuthProvider,
  MessageProvider,
  useLocalLanguage,
  useTranslation,
} from '@/core'
import { AnalyticsProvider, useAnalyticsModule } from '@/core/analytics'
import {
  useRemoveAllNativeAppListeners,
  useRemoveStorageWhenClose,
} from '@/core/native/app'
import { PlayingResourceProvider } from '@/core/resources'
import { SafeAreaViewProvider } from '@/lib/safeAreaView'

/**
 * Application root with next.js
 */
function UtoniqApp({ Component, pageProps }: AppProps) {
  useRemoveAllNativeAppListeners()
  useRemoveStorageWhenClose()
  useLocalLanguage()
  const { t } = useTranslation()
  const commonTitle = t('metaTitle')
  const commonDescription = t('metaDescription')
  const client = useApolloClient()
  const analyticsModule = useAnalyticsModule()

  return (
    <>
      <ErrorBoundary>
        <Head>
          <meta content={commonTitle ?? config.appName} property="og:title" />
          <meta content={commonDescription} property="og:description" />
          <meta content={config.meta.url} property="og:url" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, viewport-fit=cover, maximum-scale=1.0, user-scalable=no"
          />
          <meta property="og:type" content="website" />
          <link rel="manifest" href="/assets/manifest.json" />
          <Icons />
        </Head>
        <LocalizationProvider
          localeText={
            jaJP.components.MuiLocalizationProvider.defaultProps.localeText
          }
          adapterLocale={ja}
          dateAdapter={AdapterDayjs}
        >
          <AuthProvider>
            <StyleProvider>
              <AppThemeProvider>
                <ArtistThemeContextProvider>
                  <ApolloProvider client={client}>
                    <MessageProvider>
                      <AnalyticsProvider analytics={analyticsModule}>
                        <PlayingResourceProvider>
                          <SafeAreaViewProvider>
                            <DndProvider backend={HTML5Backend}>
                              <Component {...pageProps} />
                            </DndProvider>
                          </SafeAreaViewProvider>
                        </PlayingResourceProvider>
                      </AnalyticsProvider>
                    </MessageProvider>
                  </ApolloProvider>
                </ArtistThemeContextProvider>
              </AppThemeProvider>
            </StyleProvider>
          </AuthProvider>
        </LocalizationProvider>
      </ErrorBoundary>
    </>
  )
}

const Icons = () => (
  <>
    <link
      rel="icon"
      type="image/png"
      sizes="196x196"
      href="/assets/icons/favicon-196.png"
    />
    <link rel="apple-touch-icon" href="/assets/icons/apple-icon-180.png" />
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-2048-2732.jpg"
      media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-2732-2048.jpg"
      media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-1668-2388.jpg"
      media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-2388-1668.jpg"
      media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-1536-2048.jpg"
      media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-2048-1536.jpg"
      media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-1668-2224.jpg"
      media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-2224-1668.jpg"
      media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-1620-2160.jpg"
      media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-2160-1620.jpg"
      media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-1284-2778.jpg"
      media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-2778-1284.jpg"
      media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-1170-2532.jpg"
      media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-2532-1170.jpg"
      media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-1125-2436.jpg"
      media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-2436-1125.jpg"
      media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-1242-2688.jpg"
      media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-2688-1242.jpg"
      media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-828-1792.jpg"
      media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-1792-828.jpg"
      media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-1242-2208.jpg"
      media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-2208-1242.jpg"
      media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-750-1334.jpg"
      media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-1334-750.jpg"
      media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-640-1136.jpg"
      media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
    />
    <link
      rel="apple-touch-startup-image"
      href="/assets/icons/apple-splash-1136-640.jpg"
      media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
    />
  </>
)

export default UtoniqApp
