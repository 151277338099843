import {
  defaultDataIdFromObject,
  FieldFunctionOptions,
  gql,
  InMemoryCache,
  Reference,
} from '@apollo/client'
import { TIncomingRelay } from '@apollo/client/utilities/policies/pagination'
import { parsePhoneNumber } from 'libphonenumber-js'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import numeral from 'numeral'

import {
  Artist,
  ArtistMembershipSubscriptionDetailFragment,
  ArtistThreadChannelsInput,
  AvatarResources,
  BonusElementFragment,
  BonusMetadata,
  BonusType,
  Campaign,
  CheckoutFees,
  CreateLiveStreamCommentMutationVariables,
  Event,
  EventCollection,
  GachaRollAlgorithm,
  GachaTicketOrder,
  Item,
  ItemMetadata,
  ItemStatus,
  ItemType,
  Maybe,
  MembershipSubscription,
  MissionLabel,
  PaymentMethod,
  PendingPurchaseItem,
  PendingPurchaseItemMetadata,
  PendingPurchaseItemStatus,
  PickupUser,
  PortfolioItemsConnectionFragment,
  PostBoxFragment,
  PostDetail,
  PostDetailQuery,
  PostDetailQueryVariables,
  PrepaidPointBalance,
  PreSale,
  PreSaleStatus,
  PurchasableGachaTicket,
  Resource,
  ResourceFragment,
  SalesStatus,
  Scalars,
  SkuSettings,
  StoreItemDetailFragment,
  ThreadDetail,
  ThreadDetailQuery,
  ThreadDetailQueryVariables,
  ThreadStatus,
  TicketStatus,
  TradeCandidate,
  TradeCandidateNegotiationStatus,
  TradeNegotiationStatus,
  TradeRequest,
  User,
  UserAddress,
  UserCart,
  UserCartItem,
  UserCartItemSku,
  UserCartTransactionOrder,
  UserCollectionItem,
  UserGachaTicket,
  UserPaymentType,
  WallpaperResources,
} from '@/clients/utoniq-core/schema'
import { DateTime } from '@/core/entities'
import { PostalCode } from '@/core/entities/values/PostalCode'
import { getActiveMembershipSubscriptionProductPlan } from '@/core/membership'
import { dayjs } from '@/lib/dayjs'

import { PrepaidPointTransaction } from '../schema'
import { imageKitUri } from './imageKitUri'
import { purchaseGachaTicketByPoint } from './purchaseGachaTicketByPoint'
import { utoniqPagination } from './utoniqPagination'

export const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    const userId = get(responseObject, 'user.id')
    const userItemId = get(responseObject, 'id')
    switch (responseObject.__typename) {
      case 'UserItem':
        if (!userItemId || !userId) {
          return defaultDataIdFromObject(responseObject)
        }
        return [`UserItem:${userItemId}`, userId].join('-')
      case 'Card':
        return `Card:${get(
          responseObject,
          'front.uri',
          get(responseObject, 'back.uri')
        )}}`
      case 'PrepaidPointBalance':
        return get(responseObject, 'prepaidPointId')
          ? `PrepaidPointBalance:${get(responseObject, 'prepaidPointId')}`
          : defaultDataIdFromObject(responseObject)
      case 'CollectionThumbnail':
        return get(responseObject, 'uri')
          ? `CollectionThumbnail:${get(responseObject, 'uri')}`
          : defaultDataIdFromObject(responseObject)
      case 'ItemCollectContentItem':
        return get(responseObject, 'itemId')
          ? `ItemCollectContentItem:${get(responseObject, 'itemId')}`
          : defaultDataIdFromObject(responseObject)
      default:
        return defaultDataIdFromObject(responseObject)
    }
  },
  typePolicies: {
    Query: {
      fields: {
        feeds: utoniqPagination(['type']),
        /**
         * @deprecated it'll remove in the future
         */
        missions: utoniqPagination(['artistId']),
        missionsWithFilteres: utoniqPagination([
          'artistId',
          'typeFilter',
          'orderBy',
        ]),
        userCollections: utoniqPagination(['artistId']),
        prepaidPointTransactions: utoniqPagination(),
        userItemOrders: utoniqPagination(),
        gachas: utoniqPagination(['artistId']),
        deliveryItems: utoniqPagination(['statusFilter']),
        artistDeliveryItems: utoniqPagination(['statusFilter']),
        gachaTicketOrders: utoniqPagination(),
        tradeNegotiationsMyRequests: utoniqPagination(['status', 'artistId']),
        artistTradeNegotiations: utoniqPagination(['artistId']),
        tradeMyCandidates: utoniqPagination(['status', 'artistId']),
        userTradableUserItems: utoniqPagination(['userId']),
        artistThreadChannels: utoniqPagination(['inputs']),
        transactions: utoniqPagination(),
        artistItems: utoniqPagination(),
        organizationArtistItemOrders: utoniqPagination(),
        artistCollections: utoniqPagination(),
        organizationOfficialNotifications: utoniqPagination(),
        organizationOfficialArtistNotifications: utoniqPagination(['id']),
        artistMissions: utoniqPagination(['orderBy', 'typeFilter']),
        artistStepMissions: utoniqPagination(['artistId', 'type']),
        liveStreamComments: utoniqPagination(),
        purchasablePrepaidPoints: utoniqPagination(['platform']),
        storeCollections: utoniqPagination(['artistId']),
        recentlyStoreViewedItems: utoniqPagination(['artistId']),
        recentlyViewedCollections: utoniqPagination(['artistId']),
        items: utoniqPagination(['artistId', 'filters']),
        resaleItemsByUser: utoniqPagination(['status', 'userId']),
        itemResaleRaffleUsers: utoniqPagination(['status']),
        pendingPurchaseItems: utoniqPagination(['status']),
        userCarts: utoniqPagination(['id']),
        userCartTransactionOrders: utoniqPagination(),
        events: utoniqPagination(['filter']),
        pickupUsersByEvent: utoniqPagination(['eventId', 'options']),
        pickupUserEvents: utoniqPagination(),
        artistEventPickupUsers: utoniqPagination([
          'collectionId',
          'eventId',
          'options',
        ]),
        artistUserCartTransactionOrders: utoniqPagination([
          'orderBy',
          'userId',
        ]),
        artistCustomers: utoniqPagination(['orderBy', 'searchTerm']),
        ownedUserCollections: utoniqPagination(['userId']),
        preSaleLotteryApplicants: utoniqPagination(['filter']),
        artistPreSaleLotteryApplicants: utoniqPagination([
          'filters',
          'preSaleId',
        ]),
        artistEvents: utoniqPagination(['filter']),
        eventsByArtist: utoniqPagination(['artistId']),
        myPreSaleEvents: utoniqPagination(['filter']),
        artistOrganizationOfficialNotifications: utoniqPagination(['status']),
      },
    },
    Mutation: {
      fields: {
        purchaseGachaTicketByPoint: purchaseGachaTicketByPoint(),
        purchaseGachaTicketByPointInBulk: purchaseGachaTicketByPoint(),
        deletePostReply: {
          merge(existing, incoming = {}, { readField }) {
            const replyToId = readField<string>('replyToId', incoming)
            cache.modify({
              id: cache.identify({
                id: replyToId,
                __typename: 'Post',
              }),
              fields: {
                replyCount(existingReplyCount = 0) {
                  return --existingReplyCount
                },
                replies(
                  existingReplies: {
                    edges: { node: { __ref: string } }[]
                  } = { edges: [] }
                ) {
                  return {
                    ...existingReplies,
                    edges: existingReplies.edges.filter(
                      ({ node }) => node.__ref !== incoming.__ref
                    ),
                  }
                },
              },
            })
          },
        },
        applyMerchItemDelivery: {
          merge(existing, incoming = [], { cache }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                deliveryItems(existingDeliveryItemRefs = { edges: [] }) {
                  return {
                    ...existingDeliveryItemRefs,
                    edges: existingDeliveryItemRefs.edges.filter(
                      (deliveryItem: { node: { __ref: string } }) => {
                        return (
                          incoming.findIndex(
                            (deliveryItemRef: { __ref: string }) =>
                              deliveryItemRef.__ref ===
                              deliveryItem?.node?.__ref
                          ) === -1
                        )
                      }
                    ),
                  }
                },
              },
            })
            return null
          },
        },
        replyPost: {
          merge(existing, incoming, { cache, variables }) {
            const existingPost = cache.readQuery<
              PostDetailQuery,
              PostDetailQueryVariables
            >({
              query: PostDetail,
              variables: { postId: variables?.replyPostInputs?.postId },
            })
            if (!existingPost?.post) {
              return null
            }
            cache.modify({
              id: cache.identify(existingPost.post),
              fields: {
                replyCount(existingReplyCount = 0) {
                  return ++existingReplyCount
                },
                replies(existingReplies = { edges: [] }) {
                  return {
                    ...existingReplies,
                    edges: [{ node: incoming }, ...existingReplies.edges],
                  }
                },
              },
            })
            return null
          },
        },
        createTradeComment: {
          merge(existing, incoming, { cache, variables }) {
            cache.modify({
              id: cache.identify({
                id: variables?.createTradeCommentId,
                __typename: 'TradeNegotiation',
              }),
              fields: {
                comments(existingComments = { edges: [] }) {
                  return {
                    ...existingComments,
                    edges: [
                      {
                        __typename: 'Edge',
                        node: { ...incoming, __typename: 'Edge' },
                      },
                      ...existingComments.edges,
                    ],
                  }
                },
              },
            })
            return null
          },
        },
        updateUserUnread: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                artist(existingArtist, _) {
                  return {
                    ...existingArtist,
                    totalUnreadMentionCount: incoming?.totalUnreadMentionCount,
                  }
                },
              },
            })
          },
        },
        createPaymentSource: {
          merge(existing, incoming, { cache }) {
            /**
             * field: paymentSourceのキャッシュに追加
             */
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                paymentSource() {
                  return incoming
                },
              },
            })

            return null
          },
        },
        deletePaymentSource: {
          merge(existing, incoming, { cache }) {
            /**
             * field: paymentSourceのキャッシュ削除
             * filed: CreditCard:[id]のキャッシュを削除
             */
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                paymentSource(_, { DELETE }) {
                  return DELETE
                },
              },
            })
            cache.evict({ id: incoming.__ref })

            return null
          },
        },
        chargePrepaidPoint: {
          merge(_, mutationIncoming, { cache, readField, mergeObjects }) {
            const prepaidPointBalance = readField<number>(
              'prepaidPointBalance',
              mutationIncoming
            )
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                prepaidPointBalance(
                  existingPrepaidPointBalance = { __ref: '' }
                ) {
                  const prepaidPointId = readField<string>(
                    'prepaidPointId',
                    existingPrepaidPointBalance
                  )
                  return mergeObjects(existingPrepaidPointBalance, {
                    balance: prepaidPointBalance ?? 0,
                    prepaidPointId,
                    __typename: 'PrepaidPointBalance',
                  })
                },
              },
            })
            return null
          },
        },
        chargePrepaidPointByIap: {
          merge(_, mutationIncoming, { cache, readField, mergeObjects }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                prepaidPointBalance(
                  existingPrepaidPointBalance = { __ref: '' }
                ) {
                  const prepaidPointId = readField<string>(
                    'prepaidPointId',
                    existingPrepaidPointBalance
                  )
                  return mergeObjects(existingPrepaidPointBalance, {
                    balance: mutationIncoming.balance ?? 0,
                    prepaidPointId,
                    __typename: 'PrepaidPointBalance',
                  })
                },
              },
            })

            return null
          },
        },
        purchaseItemImmediately: {
          merge(_, incoming, { cache, variables, readField, mergeObjects }) {
            const price = variables?.inputs?.price ?? 0
            const paymentType = readField<UserPaymentType>(
              'paymentType',
              incoming
            )
            if (paymentType === UserPaymentType.PrepaidPoint) {
              cache.modify({
                id: 'ROOT_QUERY',
                fields: {
                  prepaidPointBalance(
                    existingPrepaidPointBalance = { __ref: '' }
                  ) {
                    const balance =
                      readField<number>(
                        'balance',
                        existingPrepaidPointBalance
                      ) ?? 0
                    const prepaidPointId = readField<string>(
                      'prepaidPointId',
                      existingPrepaidPointBalance
                    )
                    return mergeObjects(existingPrepaidPointBalance, {
                      balance: balance - price,
                      prepaidPointId,
                      __typename: 'PrepaidPointBalance',
                    })
                  },
                },
              })
            }
            return null
          },
        },
        purchaseItemByPoint: {
          merge(
            _,
            incoming,
            { cache, variables, readField, mergeObjects, isReference }
          ) {
            const price = variables?.prepaidPoint ?? 0
            const userId = readField<string>('userId', incoming)
            const item = readField<StoreItemDetailFragment>('item', incoming)
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                prepaidPointBalance(
                  existingPrepaidPointBalance = { __ref: '' }
                ) {
                  const balance =
                    readField<number>('balance', existingPrepaidPointBalance) ??
                    0
                  const prepaidPointId = readField<string>(
                    'prepaidPointId',
                    existingPrepaidPointBalance
                  )
                  return mergeObjects(existingPrepaidPointBalance, {
                    balance: balance - price,
                    prepaidPointId,
                    __typename: 'PrepaidPointBalance',
                  })
                },
              },
            })
            cache.modify({
              id: cache.identify({
                __typename: 'User',
                id: userId,
              }),
              fields: {
                organizationPortfolios(
                  existingOrganizationPortfolios = { edges: [] }
                ) {
                  return {
                    ...existingOrganizationPortfolios,
                    edges: [
                      {
                        __typename: 'Edge',
                        node: { __typename: 'UserItem', item },
                      },
                      ...existingOrganizationPortfolios.edges,
                    ],
                  }
                },
              },
            })
            return null
          },
        },
        unfollow: {
          merge(existing, incoming, { cache, readField }) {
            const user = readField<Pick<User, 'id'>>('user', incoming)
            const userId = readField<string>('id', user)
            cache.modify({
              id: cache.identify({
                id: userId,
                __typename: 'User',
              }),
              fields: {
                organizationFollowees(
                  existingOrganizationFollowees = { edges: [] }
                ) {
                  return {
                    ...existingOrganizationFollowees,
                    edges: [
                      ...existingOrganizationFollowees.edges.filter(
                        (followee: { node: { __ref: string } }) =>
                          followee.node.__ref !== incoming.__ref
                      ),
                    ],
                  }
                },
              },
            })
            return null
          },
        },
        blockUser: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                user(existingUser, _) {
                  return {
                    ...existingUser,
                    organizationFollowees: {
                      ...existingUser?.organizationFollowees,
                      edges: existingUser?.organizationFollowees?.edges.filter(
                        (followee: { node: { __ref: string } }) =>
                          followee.node.__ref !== incoming.__ref
                      ),
                    },
                  }
                },
              },
            })
          },
        },
        follow: {
          merge(existing, incoming, { cache, readField }) {
            const user = readField<Pick<User, 'id'>>('user', incoming)
            const userId = readField<string>('id', user)
            cache.modify({
              id: cache.identify({
                id: userId,
                __typename: 'User',
              }),
              fields: {
                organizationFollowees(
                  existingOrganizationFollowees = { edges: [] }
                ) {
                  return {
                    ...existingOrganizationFollowees,
                    edges: [
                      { node: { __ref: incoming.__ref }, __typename: 'Edge' },
                      ...existingOrganizationFollowees.edges,
                    ],
                  }
                },
              },
            })
            return null
          },
        },
        cancelTradeRequest: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              fields: {
                tradeNegotiationsMyRequests(
                  existingTradeRefs = { edges: [] },
                  { storeFieldName }
                ) {
                  if (
                    storeFieldName.includes(
                      `"${TradeNegotiationStatus.InProgress}"`
                    )
                  ) {
                    return {
                      ...existingTradeRefs,
                      edges: existingTradeRefs.edges.filter(
                        (trade: { node: { ref: string } }) =>
                          trade.node.ref !== incoming.ref
                      ),
                    }
                  }
                  return existingTradeRefs
                },
              },
            })
          },
        },
        acceptTradeRequest: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              fields: {
                tradeMyCandidates(
                  existingTradeRefs = { edges: [] },
                  { storeFieldName }
                ) {
                  if (
                    storeFieldName.includes(
                      `"${TradeNegotiationStatus.InProgress}"`
                    )
                  ) {
                    return {
                      ...existingTradeRefs,
                      edges: existingTradeRefs.edges.filter(
                        (trade: {
                          node: { tradeNegotiation: { __ref: string } }
                        }) =>
                          trade?.node?.tradeNegotiation?.__ref !==
                          incoming?.__ref
                      ),
                    }
                  }
                  if (
                    storeFieldName.includes(
                      `"${TradeCandidateNegotiationStatus.Accepted}"`
                    )
                  ) {
                    return {
                      ...existingTradeRefs,
                      edges: [
                        {
                          node: {
                            tradeNegotiation: incoming,
                            __typename: 'TradeCandidate',
                          },
                          __typename: 'Edge',
                        },
                        ...existingTradeRefs.edges,
                      ],
                    }
                  }
                  return existingTradeRefs
                },
              },
            })
          },
        },
        declineTradeRequest: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              fields: {
                tradeMyCandidates(
                  existingTradeRefs = { edges: [] },
                  { storeFieldName }
                ) {
                  if (
                    storeFieldName.includes(
                      `"${TradeCandidateNegotiationStatus.InProgress}"`
                    )
                  ) {
                    return {
                      ...existingTradeRefs,
                      edges: existingTradeRefs.edges.filter(
                        (trade: {
                          node: { tradeNegotiation: { __ref: string } }
                        }) =>
                          trade?.node?.tradeNegotiation?.__ref !==
                          incoming?.__ref
                      ),
                    }
                  }
                  if (
                    storeFieldName.includes(
                      `"${TradeCandidateNegotiationStatus.Rejected}"`
                    )
                  ) {
                    return {
                      ...existingTradeRefs,
                      edges: [
                        {
                          node: {
                            tradeNegotiation: incoming,
                            __typename: 'TradeCandidate',
                          },
                          __typename: 'Edge',
                        },
                        ...existingTradeRefs.edges,
                      ],
                    }
                  }
                  return existingTradeRefs
                },
              },
            })
          },
        },
        updateUserNotificationSettings: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                user(existingUser, _) {
                  return { ...existingUser, settings: incoming?.settings }
                },
              },
            })
          },
        },
        updateLastNotificationReadAt: {
          merge(existing, incoming, { cache }) {
            const userData = cache.readQuery<{ user?: { id: string } }>({
              query: gql`
                query {
                  user {
                    id
                  }
                }
              `,
            })
            cache.modify({
              id: cache.identify({
                __typename: 'User',
                id: userData?.user?.id,
              }),
              fields: {
                unreadNotificationCount() {
                  return 0
                },
              },
            })
          },
        },
        createArtistThreadChannel: {
          merge(existing, incoming, { cache, variables }) {
            if (variables?.inputs?.status === ThreadStatus.Draft) {
              return
            }
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                artistThreadChannels(existingArtistThreadChannels, options) {
                  const inputs = parseVariables<ArtistThreadChannelsInput>(
                    options.storeFieldName
                  )
                  if (
                    inputs?.artistId === variables?.inputs.artistId ||
                    !inputs?.options?.filter
                  ) {
                    return {
                      ...existingArtistThreadChannels,
                      edges: [
                        {
                          __typename: 'Edge',
                          node: { __ref: incoming.__ref },
                        },
                        ...existingArtistThreadChannels.edges,
                      ],
                    }
                  }
                  return { ...existingArtistThreadChannels }
                },
              },
            })
          },
        },
        createArtistThreadComment: {
          merge(existing, incoming, { cache, variables, readField }) {
            const createdAt = readField<number>('createdAt', incoming)
            const threadCached = cache.readQuery<
              ThreadDetailQuery,
              ThreadDetailQueryVariables
            >({
              query: ThreadDetail,
              variables: { artistThreadChannelId: variables?.id },
            })
            if (threadCached) {
              cache.modify({
                id: cache.identify({
                  __typename: 'UserUnread',
                  id: threadCached.artistThreadChannel.userUnread?.id,
                }),
                fields: {
                  lastReadAt(existingLastReadAt) {
                    return createdAt ? createdAt + 1 : existingLastReadAt
                  },
                },
              })
            }
            if (!variables?.inputs.replyToId) {
              cache.modify({
                id: cache.identify({
                  __typename: 'ArtistThreadChannel',
                  id: variables?.id,
                }),
                fields: {
                  comments(existingComments = { edges: [] }) {
                    return {
                      ...existingComments,
                      edges: [
                        { __typename: 'Edge', node: incoming },
                        ...existingComments.edges,
                      ],
                    }
                  },
                  commentCount(existingCommentCount = 0) {
                    return ++existingCommentCount
                  },
                  latestComment() {
                    return incoming
                  },
                  commentUsers(existingCommentUsers = [], { readField }) {
                    const commenterRef = readField<{ __ref: string }>(
                      'commenter',
                      incoming
                    )
                    if (!existingCommentUsers.length && commenterRef?.__ref) {
                      return [commenterRef]
                    }
                    if (
                      commenterRef?.__ref &&
                      !existingCommentUsers.some(
                        (commentUser: { __ref?: string }) =>
                          commentUser?.__ref === commenterRef.__ref
                      )
                    ) {
                      return [...existingCommentUsers, commenterRef]
                    }
                    return existingCommentUsers
                  },
                },
              })
              return
            }
            cache.modify({
              id: cache.identify({
                __typename: 'Comment',
                id: variables?.inputs?.replyToId,
              }),
              fields: {
                replyCount(existingReplyCount = 0) {
                  return ++existingReplyCount
                },
                replies(existingReplies = { edges: [] }) {
                  return {
                    ...existingReplies,
                    edges: [
                      { __typename: 'Edge', node: incoming },
                      ...existingReplies.edges,
                    ],
                  }
                },
              },
            })
          },
        },
        deleteArtistThreadComment: {
          merge(
            existing,
            incoming,
            { cache, variables, toReference, readField }
          ) {
            const parentComment = readField<Comment>('parentComment', incoming)
            const parentCommentId = readField<Comment>('id', parentComment)
            if (parentCommentId) {
              cache.modify({
                id: cache.identify({
                  __typename: 'Comment',
                  id: parentCommentId,
                }),
                fields: {
                  replies(existingReplies = { edges: [] }) {
                    return {
                      ...existingReplies,
                      edges: [
                        ...existingReplies.edges.filter(
                          (edge: { node: { __ref: string } }) =>
                            edge.node.__ref !== incoming.__ref
                        ),
                      ],
                    }
                  },
                  replyCount(existingReplyCount = 0) {
                    return existingReplyCount > 0 ? --existingReplyCount : 0
                  },
                },
              })
              return
            }
            const threadCached = cache.readQuery<
              ThreadDetailQuery,
              ThreadDetailQueryVariables
            >({
              query: ThreadDetail,
              variables: { artistThreadChannelId: variables?.id },
            })
            cache.modify({
              id: cache.identify({
                __typename: 'ArtistThreadChannel',
                id: variables?.id,
              }),
              fields: {
                comments(existingComments = { edges: [] }) {
                  return {
                    ...existingComments,
                    edges: [
                      ...existingComments.edges.filter(
                        (edge: { node: { __ref: string } }) =>
                          edge.node.__ref !== incoming.__ref
                      ),
                    ],
                  }
                },
                commentCount(existingCommentCount = 0) {
                  return existingCommentCount > 1 ? --existingCommentCount : 0
                },
                latestComment(existingLastedComment = {}) {
                  if (existingLastedComment.__ref !== incoming.__ref) {
                    return existingLastedComment
                  }
                  if (
                    !threadCached?.artistThreadChannel?.comments?.edges?.[1]
                      ?.node
                  ) {
                    return null
                  }
                  return toReference(
                    threadCached.artistThreadChannel.comments.edges[1].node
                  )
                },
                commentUsers(existingCommentUsers = [], { readField }) {
                  const commenterRef = readField<{ __ref: string }>(
                    'commenter',
                    incoming
                  )
                  const commentUsersCache =
                    threadCached?.artistThreadChannel?.comments?.edges?.map(
                      ({ node }) => toReference(node?.commenter)
                    ) ?? []
                  if (
                    commentUsersCache.filter(
                      commentUserCache =>
                        commentUserCache?.__ref === commenterRef?.__ref
                    ).length > 1
                  ) {
                    return existingCommentUsers
                  }
                  return [
                    ...existingCommentUsers.filter(
                      (existingCommentUser: { __ref?: string }) =>
                        existingCommentUser?.__ref !== commenterRef?.__ref
                    ),
                  ]
                },
              },
            })
            cache.evict({
              id: cache.identify({
                __typename: 'Comment',
                id: variables?.commentId,
              }),
            })
            cache.gc()
          },
        },
        createMissionComment: {
          merge(
            existing: Reference,
            incoming: Reference,
            { cache, variables }: FieldFunctionOptions
          ) {
            if (!variables?.inputs?.replyToId) {
              cache.modify({
                id: cache.identify({
                  __typename: 'Mission',
                  id: variables?.id,
                }),
                fields: {
                  commentCount(existingCommentCount = 0) {
                    return ++existingCommentCount
                  },
                  comments(existingComments = { edges: [] }) {
                    return {
                      ...existingComments,
                      edges: [
                        { __typename: 'Edge', node: incoming },
                        ...existingComments.edges,
                      ],
                    }
                  },
                },
              })
              return
            }
            cache.modify({
              id: cache.identify({
                __typename: 'Comment',
                id: variables?.inputs?.replyToId,
              }),
              fields: {
                replyCount(existingReplyCount = 0) {
                  return ++existingReplyCount
                },
                replies(existingReplies = { edges: [] }) {
                  return {
                    ...existingReplies,
                    edges: [
                      { __typename: 'Edge', node: incoming },
                      ...existingReplies.edges,
                    ],
                  }
                },
              },
            })
          },
        },
        deleteMissionComment: {
          merge(existing, incoming, { cache, variables, readField }) {
            const parentComment = readField<Comment>('parentComment', incoming)
            const parentCommentId = readField<Comment>('id', parentComment)
            if (parentCommentId) {
              cache.modify({
                id: cache.identify({
                  __typename: 'Comment',
                  id: parentCommentId,
                }),
                fields: {
                  replies(existingReplies = { edges: [] }) {
                    return {
                      ...existingReplies,
                      edges: [
                        ...existingReplies.edges.filter(
                          (edge: { node: { __ref: string } }) =>
                            edge.node.__ref !== incoming.__ref
                        ),
                      ],
                    }
                  },
                  replyCount(existingReplyCount = 0) {
                    return existingReplyCount > 0 ? --existingReplyCount : 0
                  },
                },
              })
              return
            }
            cache.modify({
              id: cache.identify({
                __typename: 'Mission',
                id: variables?.id,
              }),
              fields: {
                commentCount(existingCommentCount = 0) {
                  return existingCommentCount > 0 ? --existingCommentCount : 0
                },
                comments(existingComments = { edges: [] }) {
                  return {
                    ...existingComments,
                    edges: [
                      ...existingComments.edges.filter(
                        (edge: { node: { __ref: string } }) =>
                          edge.node.__ref !== incoming.__ref
                      ),
                    ],
                  }
                },
              },
            })
            cache.evict({
              id: cache.identify({
                __typename: 'Comment',
                id: variables?.commentId,
              }),
            })
            cache.gc()
          },
        },
        createItemComment: {
          merge(existing, incoming, { cache, variables }) {
            if (!variables?.inputs.replyToId) {
              cache.modify({
                id: cache.identify({
                  __typename: 'Item',
                  id: variables?.id,
                }),
                fields: {
                  comments(existingComments = { edges: [] }) {
                    return {
                      ...existingComments,
                      edges: [
                        { __typename: 'Edge', node: incoming },
                        ...existingComments.edges,
                      ],
                    }
                  },
                  commentCount(existingCommentCount = 0) {
                    return ++existingCommentCount
                  },
                },
              })
              return
            }
            cache.modify({
              id: cache.identify({
                __typename: 'Comment',
                id: variables?.inputs?.replyToId,
              }),
              fields: {
                replyCount(existingReplyCount = 0) {
                  return ++existingReplyCount
                },
                replies(existingReplies = { edges: [] }) {
                  return {
                    ...existingReplies,
                    edges: [
                      { __typename: 'Edge', node: incoming },
                      ...existingReplies.edges,
                    ],
                  }
                },
              },
            })
          },
        },
        deleteItemComment: {
          merge(existing, incoming, { cache, variables, readField }) {
            const parentComment = readField<Comment>('parentComment', incoming)
            const parentCommentId = readField<Comment>('id', parentComment)
            if (parentCommentId) {
              cache.modify({
                id: cache.identify({
                  __typename: 'Comment',
                  id: parentCommentId,
                }),
                fields: {
                  replies(existingReplies = { edges: [] }) {
                    return {
                      ...existingReplies,
                      edges: [
                        ...existingReplies.edges.filter(
                          (edge: { node: { __ref: string } }) =>
                            edge.node.__ref !== incoming.__ref
                        ),
                      ],
                    }
                  },
                  replyCount(existingReplyCount = 0) {
                    return existingReplyCount > 0 ? --existingReplyCount : 0
                  },
                },
              })
              return
            }
            cache.modify({
              id: cache.identify({
                __typename: 'Item',
                id: variables?.id,
              }),
              fields: {
                comments(existingComments = { edges: [] }) {
                  return {
                    ...existingComments,
                    edges: [
                      ...existingComments.edges.filter(
                        (edge: { node: { __ref: string } }) =>
                          edge.node.__ref !== incoming.__ref
                      ),
                    ],
                  }
                },
                commentCount(existingCommentCount = 0) {
                  return existingCommentCount > 0 ? --existingCommentCount : 0
                },
              },
            })
            cache.evict({
              id: cache.identify({
                __typename: 'Comment',
                id: variables?.commentId,
              }),
            })
            cache.gc()
          },
        },
        archiveArtistThreadChannel: {
          merge(existing, incoming, { cache, variables }) {
            cache.evict({
              id: cache.identify({
                __typename: 'ArtistThreadChannel',
                id: variables?.archiveArtistThreadChannelId,
              }),
            })
            cache.gc()
          },
        },
        createPost: {
          merge(existing, incoming, { cache, readField }) {
            const artistId = readField<PostBoxFragment['artistId']>(
              'artistId',
              incoming
            )
            cache.modify({
              id: cache.identify({
                __typename: 'Artist',
                id: artistId,
              }),
              fields: {
                posts(existingPosts = { edges: [] }) {
                  return {
                    ...existingPosts,
                    edges: [
                      { __typename: 'Edge', node: incoming },
                      ...existingPosts.edges,
                    ],
                  }
                },
              },
            })
          },
        },
        deletePost: {
          merge(existing, incoming, { cache, readField }) {
            const artistId = readField<string>('artistId', incoming)
            cache.modify({
              id: cache.identify({
                __typename: 'Artist',
                id: artistId,
              }),
              fields: {
                posts(existingPosts = { edges: [] }) {
                  return {
                    ...existingPosts,
                    edges: [
                      ...existingPosts.edges.filter(
                        (edge: { node: { __ref: string } }) =>
                          edge.node.__ref !== incoming.__ref
                      ),
                    ],
                  }
                },
              },
            })
          },
        },
        checkoutCart: {
          merge(existing, incoming, { cache, readField }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                userCartCount(existingUserCartCount = 0) {
                  return existingUserCartCount > 0
                    ? existingUserCartCount - 1
                    : 0
                },
              },
            })
            // コンビニ決済時にユーザーのコンビニ支払いフラグを更新
            if (readField<UserCartTransactionOrder>('isKonbini', incoming)) {
              cache.modify({
                id: 'ROOT_QUERY',
                fields: {
                  user(existingUser, _) {
                    return { ...existingUser, hasWaitingPaymentItems: true }
                  },
                },
              })
            }
          },
        },
        deleteCart: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                userCarts(existingCarts = { edges: [] }) {
                  return {
                    ...existingCarts,
                    edges: [
                      ...existingCarts.edges.filter(
                        (edge: { node: { __ref: string } }) =>
                          edge.node.__ref !== incoming.__ref
                      ),
                    ],
                  }
                },
                userCartCount(existingUserCartCount = 0) {
                  return existingUserCartCount > 0
                    ? existingUserCartCount - 1
                    : 0
                },
              },
            })
          },
        },
        createAcceptedItem: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                artistItems(existingArtistItems = { edges: [] }) {
                  return {
                    ...existingArtistItems,
                    edges: [
                      { __typename: 'Edge', node: incoming },
                      ...existingArtistItems.edges,
                    ],
                  }
                },
              },
            })
          },
        },
        artistRemoveItem: {
          merge(existing, incoming, { cache, variables }) {
            cache.evict({
              id: cache.identify({
                __typename: 'Item',
                id: variables?.id,
              }),
            })
            cache.gc()
          },
        },
        createCollection: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                artistCollections(existingArtistCollections = { edges: [] }) {
                  return {
                    ...existingArtistCollections,
                    edges: [
                      { __typename: 'Edge', node: { ...incoming } },
                      ...existingArtistCollections.edges,
                    ],
                  }
                },
              },
            })
          },
        },
        addItemToCollection: {
          merge(existing, incoming, { cache, variables }) {
            cache.modify({
              id: cache.identify({
                __typename: 'Collection',
                id: variables?.collectionId,
              }),
              fields: {
                itemCount(existingItemCount = 0) {
                  return existingItemCount + 1
                },
                collectionItems(existingCollectionItems = { edges: [] }) {
                  return {
                    ...existingCollectionItems,
                    edges: [
                      ...existingCollectionItems.edges,
                      { __typename: 'Edge', node: incoming },
                    ],
                  }
                },
              },
            })
          },
        },
        removeItemFromCollection: {
          merge(existing, incoming, { cache, variables }) {
            cache.modify({
              id: cache.identify({
                __typename: 'Collection',
                id: variables?.collectionId,
              }),
              fields: {
                itemCount(existingItemCount = 0) {
                  return existingItemCount - 1
                },
                collectionItems(existingCollectionItems = { edges: [] }) {
                  const collectionItemId = cache.identify({
                    __typename: 'CollectionItem',
                    id: variables?.itemId,
                  })
                  return {
                    ...existingCollectionItems,
                    edges: existingCollectionItems.edges.filter(
                      (existingCollectionItem: { node: { __ref: string } }) =>
                        existingCollectionItem.node.__ref !== collectionItemId
                    ),
                  }
                },
              },
            })
            cache.modify({
              id: cache.identify({
                __typename: 'Item',
                id: variables?.itemId,
              }),
              fields: {
                collection() {
                  return null
                },
              },
            })
          },
        },
        removeCollection: {
          merge(existing, incoming, { cache, variables }) {
            cache.evict({
              id: cache.identify({
                __typename: 'Collection',
                id: variables?.id,
              }),
            })
            cache.gc()
          },
        },
        createIapSubscription: {
          merge(existing, incoming, { cache, readField }) {
            const userRef = readField<{ __ref: string }>('user', incoming)
            cache.modify({
              id: userRef?.__ref,
              fields: {
                organizationPatrons(existingOrganizationPatrons = []) {
                  return [incoming, ...existingOrganizationPatrons]
                },
              },
            })
          },
        },
        startSubscription: {
          merge(existing, incoming, { cache, readField }) {
            const userRef = readField<{ __ref: string }>('user', incoming)
            cache.modify({
              id: userRef?.__ref,
              fields: {
                organizationPatrons(existingOrganizationPatrons = []) {
                  return [incoming, ...existingOrganizationPatrons]
                },
              },
            })
          },
        },
        stopSubscription: {
          merge(existing, incoming, { cache, variables }) {
            cache.evict({
              id: cache.identify({
                __typename: 'Patron',

                id: variables?.id,
              }),
            })
          },
        },
        createMission: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                artistMissions(existingArtistMissions = { edges: [] }) {
                  return {
                    ...existingArtistMissions,
                    edges: [
                      { __typename: 'Edge', node: incoming },
                      ...existingArtistMissions.edges,
                    ],
                  }
                },
              },
            })
          },
        },
        deleteMission: {
          merge(existing, incoming, { cache, variables }) {
            cache.evict({
              id: cache.identify({
                __typename: 'Mission',
                id: variables?.id,
              }),
            })
            cache.gc()
          },
        },
        artistUsekets: {
          merge(existing, incoming, { cache, variables }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                portfolio(existing, _) {
                  return {
                    ...existing,
                    ...incoming,
                  }
                },
              },
            })
          },
        },
        artistUnUsekets: {
          merge(existing, incoming, { cache, variables }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                portfolio(existing, _) {
                  return {
                    ...existing,
                    ...incoming,
                  }
                },
              },
            })
          },
        },
        artistUseEventPickupTickets: {
          merge(existing, incoming, { cache, variables }) {
            cache.modify({
              id: cache.identify({
                __typename: 'PickupUser',
                id: variables?.inputs.pickupUserId,
              }),
              fields: {
                isCompleted() {
                  return true
                },
              },
            })
          },
        },
        artistUnuseEventPickupTickets: {
          merge(existing, incoming, { cache, variables }) {
            cache.modify({
              id: cache.identify({
                __typename: 'PickupUser',
                id: variables?.inputs.pickupUserId,
              }),
              fields: {
                isCompleted() {
                  return false
                },
              },
            })
          },
        },
        updateCustomProfile: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                user(existingUser, _) {
                  return {
                    ...existingUser,
                    customProfile: incoming?.customProfile,
                  }
                },
              },
            })
          },
        },
        obtainLoginBonus: {
          merge(existing, incoming, { cache, readField, mergeObjects }) {
            const loginBonusUserRef = readField<Reference>(
              'loginBonusUser',
              incoming
            )
            const obtainedBonusesRefs = readField<Reference[]>(
              'obtainedBonuses',
              loginBonusUserRef
            )
            const lastObtainedBonusesRef =
              obtainedBonusesRefs?.[obtainedBonusesRefs?.length - 1]
            const type = readField<BonusType>('type', lastObtainedBonusesRef)
            const metadata = readField<BonusMetadata>(
              'metadata',
              lastObtainedBonusesRef
            )
            if (type === BonusType.Point) {
              cache.modify({
                id: 'ROOT_QUERY',
                fields: {
                  prepaidPointBalance(
                    existingPrepaidPointBalance = { __ref: '' }
                  ) {
                    const balance =
                      readField<number>(
                        'balance',
                        existingPrepaidPointBalance
                      ) ?? 0
                    const prepaidPointId = readField<string>(
                      'prepaidPointId',
                      existingPrepaidPointBalance
                    )
                    return mergeObjects(existingPrepaidPointBalance, {
                      balance: balance + (metadata?.point ?? 0),
                      prepaidPointId,
                      __typename: 'PrepaidPointBalance',
                    })
                  },
                },
              })
            }
          },
        },
        createLiveStreamComment: {
          merge(
            existing,
            incoming,
            { cache, variables, readField, mergeObjects }
          ) {
            const points =
              (variables as CreateLiveStreamCommentMutationVariables)?.inputs
                ?.tip?.points || 0
            if (points) {
              cache.modify({
                id: 'ROOT_QUERY',
                fields: {
                  prepaidPointBalance(
                    existingPrepaidPointBalance = { __ref: '' }
                  ) {
                    const balance =
                      readField<number>(
                        'balance',
                        existingPrepaidPointBalance
                      ) ?? 0
                    const prepaidPointId = readField<string>(
                      'prepaidPointId',
                      existingPrepaidPointBalance
                    )
                    return mergeObjects(existingPrepaidPointBalance, {
                      balance: balance - points,
                      prepaidPointId,
                      __typename: 'PrepaidPointBalance',
                    })
                  },
                },
              })
            }
          },
        },
        useTicket: {
          merge(existing, incoming, { cache, variables, readField }) {
            const obtainedDate = readField<string>('obtainedDate', incoming)
            const ticketStatus = readField<TicketStatus>(
              'ticketStatus',
              incoming
            )
            cache.modify({
              id: cache.identify({
                id: variables?.inputs?.serialId,
                __typename: 'UserItemSerial',
              }),
              fields: {
                isUsed() {
                  return true
                },
                obtainedDate() {
                  return obtainedDate
                },
              },
            })
            cache.modify({
              id: cache.identify({
                id: variables?.inputs?.serialId,
                __typename: 'Serial',
              }),
              fields: {
                ticketStatus() {
                  return ticketStatus
                },
              },
            })
          },
        },
        verifySMSCode: {
          merge(existing, incoming, { cache, readField }) {
            const verified = readField<boolean>('verified', incoming)
            //get current login
            const userData = cache.readQuery<{ user?: { id: string } }>({
              query: gql`
                query {
                  user {
                    id
                  }
                }
              `,
            })
            cache.modify({
              id: cache.identify({
                id: userData?.user?.id,
                __typename: 'User',
              }),
              fields: {
                isPhoneNumberVerified() {
                  return verified
                },
              },
            })
          },
        },
        artistCreatePreSale: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                preSales(existing = { edges: [] }) {
                  return {
                    ...existing,
                    edges: [
                      { __typename: 'Edge', node: incoming },
                      ...existing.edges,
                    ],
                  }
                },
              },
            })
          },
        },
        artistUpdatePreSale: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                artistPreSales(existing = { edges: [] }) {
                  return {
                    ...existing,
                    edges: existing.edges.map(
                      (edge: { node: { __ref: string } }) => {
                        if (edge.node.__ref === incoming.__ref) {
                          return { __typename: 'Edge', node: incoming }
                        }
                        return edge
                      }
                    ),
                  }
                },
              },
            })
          },
        },
        artistRemoveCollectionFromEvent: {
          merge(existing, incoming, { cache, variables }) {
            const eventCollectionId = cache.identify({
              __typename: 'EventCollection',
              id: variables?.inputs.collectionId,
            })
            cache.modify({
              id: cache.identify({
                __typename: 'Event',
                id: variables?.inputs?.eventId,
              }),
              fields: {
                eventCollections(existingEventCollections = { edges: [] }) {
                  return {
                    ...existingEventCollections,
                    edges: existingEventCollections.edges.filter(
                      (existingEventCollection: { node: { __ref: string } }) =>
                        existingEventCollection.node.__ref !== eventCollectionId
                    ),
                  }
                },
              },
            })
            cache.modify({
              id: eventCollectionId,
              fields: {
                event() {
                  return null
                },
              },
            })
          },
        },
        artistDeleteEvent: {
          merge(existing, incoming, { cache, variables }) {
            cache.evict({
              id: cache.identify({
                __typename: 'Event',
                id: variables?.id,
              }),
            })
            cache.gc()
          },
        },
        cancelTicketDistribution: {
          merge(_, incoming, { cache, readField }) {
            const serialId = readField<string>('serialId', incoming)
            cache.modify({
              id: cache.identify({
                id: serialId,
                __typename: 'ItemSerial',
              }),
              fields: {
                ticketDistributionLink() {
                  return null
                },
              },
            })
            cache.evict({ id: incoming.__ref })
            cache.gc()
          },
        },
        artistCreateOrganizationNotification: {
          merge(existing, incoming, { cache }) {
            cache.modify({
              id: 'ROOT_QUERY',
              fields: {
                artistOrganizationOfficialNotifications(
                  existingArtistOrganizationOfficialNotifications = {
                    edges: [],
                  }
                ) {
                  return {
                    ...existingArtistOrganizationOfficialNotifications,
                    edges: [
                      { __typename: 'Edge', node: incoming },
                      ...existingArtistOrganizationOfficialNotifications.edges,
                    ],
                  }
                },
              },
            })
          },
        },
        artistArchiveOrganizationNotification: {
          merge(existing, incoming, { cache, variables }) {
            cache.evict({
              id: cache.identify({
                __typename: 'OrganizationNotification',
                id: variables?.id,
              }),
            })
            cache.gc()
          },
        },
      },
    },
    Comment: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.formatRelative(createdAt) : null
        },
        /**
         * hack if enable this then user post comment not show on list
         */
        // replies: utoniqPagination(),
      },
    },
    Post: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.formatRelative(createdAt) : null
        },
        formattedPublishedAt(_, { readField }) {
          const publishedAt = readField<number>('publishedAt')
          return publishedAt
            ? String(DateTime.formatDate(publishedAt, 'LL HH:mm') ?? '')
            : null
        },
        formattedRelativePublishedAt(_, { readField }) {
          const publishedAt = readField<number>('publishedAt')
          return publishedAt ? DateTime.formatRelative(publishedAt) : null
        },
        planTitle: {
          read(_, { readField }) {
            const artist = readField<Artist>('artist')
            const planId = readField('planId')

            if (!planId || !artist) return ''

            const subscription = readField<MembershipSubscription>(
              'subscription',
              artist
            )

            //ポストのプランIDに当てはまるアーティストのプラン情報を取得
            if (subscription?.products?.length) {
              const product = subscription.products.find(product =>
                product.plans.some(plan => plan.planId === planId)
              )
              if (product) {
                return product.title
              }
            }
            const postPlan = subscription?.plans?.find(
              plan => plan.planId === planId
            )

            return postPlan?.title
          },
        },
        replies: utoniqPagination(),
      },
    },
    User: {
      fields: {
        artistPortfolios: utoniqPagination(['type']),
        formattedAccountId(_, { readField }) {
          const accountId = readField<string>('accountId')
          return accountId ? `@${accountId}` : null
        },
        formattedBirthday(_, { readField }) {
          const birthday = readField<number>('birthday')
          return birthday ? DateTime.formatDate(birthday) : null
        },
        formattedPhoneNumber(_, { readField }) {
          const phoneNumber = readField<string>('phoneNumber')
          return formatPhoneNumber(phoneNumber)
        },
        hasManagementTask(_, { readField }) {
          const hasWaitingPaymentItems = readField<string>(
            'hasWaitingPaymentItems'
          )

          const hasUnappliedDeliveryItems = readField<string>(
            'hasUnappliedDeliveryItems'
          )

          return !!(hasWaitingPaymentItems || hasUnappliedDeliveryItems)
        },

        organizationPortfolios: utoniqPagination(
          ['type'],
          (
            value: TIncomingRelay<PortfolioItemsConnectionFragment>,
            { args, readField }
          ) => {
            // リセールのフィルタが有効な時、リセール出品可能なアイテムのみにフィルタする
            if (args?.type === ItemType.Ticket && args.option?.resaleEnabled) {
              return {
                ...value,
                edges: value?.edges?.filter(edge => {
                  const item = readField<Item>('item', (edge as any).node)
                  return readField<boolean>('isResaleAvailable', item)
                }),
              }
            }

            return value
          }
        ),
      },
    },
    Artist: {
      fields: {
        formattedAccountId(_, { readField }) {
          const accountId = readField<string>('accountId')
          return accountId ? `@${accountId}` : null
        },
        formattedPatronCount(_, { readField }) {
          const patronCount = readField<string>('patronCount')
          return formatNumber(patronCount)
        },
        posts: utoniqPagination([
          'artistId',
          'resourceType',
          'status',
          'categoryId',
        ]),
        patrons: utoniqPagination(['filters']),
      },
    },
    Notification: {
      fields: {
        formattedDate(_, { readField }) {
          const date = readField<number>('date')
          return date ? DateTime.format(date) : null
        },
      },
    },
    UserItemSerial: {
      fields: {
        formattedObtainedDate(_, { readField }) {
          const obtainedDate = readField<number>('obtainedDate')
          return obtainedDate ? DateTime.formatDate(obtainedDate) : null
        },
        formattedObtainedDateTime(_, { readField }) {
          const obtainedDate = readField<number>('obtainedDate')
          return obtainedDate ? DateTime.format(obtainedDate) : null
        },
      },
    },
    Gacha: {
      fields: {
        formattedEndAt(_, { readField }) {
          const endAt = readField<number>('endAt')
          return endAt ? DateTime.format(endAt) : null
        },
        isSoldOut(_, { readField }) {
          const rollAlgorithm = readField<GachaRollAlgorithm>('rollAlgorithm')
          if (rollAlgorithm === GachaRollAlgorithm.Limitless) {
            return false
          }
          const remainingLimitedItemCount = readField<number>(
            'remainingLimitedItemCount'
          )
          return !remainingLimitedItemCount
        },
        isLimited(_, { readField }) {
          const rollAlgorithm = readField<GachaRollAlgorithm>('rollAlgorithm')
          return rollAlgorithm === GachaRollAlgorithm.Limited
        },
      },
    },
    Mission: {
      fields: {
        formattedExpiredAt(_, { readField }) {
          const expiredAt = readField<number>('expiredAt')
          return expiredAt ? DateTime.format(expiredAt) : null
        },
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.format(createdAt) : null
        },
        label(_, { readField }) {
          const expiredAt = readField<number>('expiredAt') ?? 0
          const isAccomplished = !!readField<boolean>('isAccomplished')
          if (isAccomplished) {
            return MissionLabel.Clear
          }
          return expiredAt < DateTime.nowUnixMilliseconds()
            ? MissionLabel.Expired
            : MissionLabel.New
        },
        comments: utoniqPagination(),
      },
    },
    UserStepMission: {
      fields: {
        label(_, { readField }) {
          const isAccomplished = !!readField<boolean>('isAccomplished')
          if (isAccomplished) {
            return MissionLabel.Clear
          }

          return MissionLabel.New
          /**
           * @todo
           * 期限切れはstepMissionにてAPI未実装
           *
           * const expiredAt = readField<number>('expiredAt') ?? 0
           * return expiredAt < DateTime.nowUnixMilliseconds()
           *  ? MissionLabel.Expired
           *  : MissionLabel.New
           */
        },
      },
    },
    ItemSerial: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.formatDate(createdAt) : null
        },
        formattedObtainedDate(_, { readField }) {
          const obtainedDate = readField<number>('obtainedDate')
          return obtainedDate ? DateTime.formatDate(obtainedDate) : null
        },
        formattedObtainedDateTime(_, { readField }) {
          const obtainedDate = readField<number>('obtainedDate')
          return obtainedDate ? DateTime.format(obtainedDate) : null
        },
      },
    },
    GachaItem: {
      fields: {
        weight(weight) {
          const percentWeight = (weight * 100).toPrecision(3)
          return `${percentWeight}%`
        },
      },
    },
    PrepaidPointTransaction: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.format(createdAt) : null
        },
        formattedTransactionPrepaidPoint: (_, { readField }) => {
          return formatNumber(
            readField<
              Maybe<PrepaidPointTransaction['transactionPrepaidPoint']>
            >('transactionPrepaidPoint') ?? 0
          )
        },
      },
    },
    ItemOrder: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.format(createdAt) : null
        },
      },
    },
    DeliveryItem: {
      fields: {
        formattedAppliedAt(_, { readField }) {
          const appliedAt = readField<number>('appliedAt')
          return appliedAt ? DateTime.format(appliedAt) : null
        },
        formattedShippedAt(_, { readField }) {
          const shippedAt = readField<number>('shippedAt')
          return shippedAt ? DateTime.format(shippedAt) : null
        },
      },
    },
    UserAddress: {
      fields: {
        fullAddress(_, { readField }) {
          const postalCode =
            readField<Maybe<UserAddress['postalCode']>>('postalCode') ?? ''
          const prefecture =
            readField<Maybe<UserAddress['prefecture']>>('prefecture') ?? ''
          const city = readField<Maybe<UserAddress['city']>>('city') ?? ''
          const line1 = readField<Maybe<UserAddress['line1']>>('line1') ?? ''
          const line2 = readField<Maybe<UserAddress['line2']>>('line2') ?? ''

          // 郵便番号・都道府県・市区町村・番地がすべて入力されていなければ、fullAddressはnullとして返す
          if (!(postalCode && prefecture && city && line1)) return null

          return `${PostalCode.format(
            postalCode
          )}\n${prefecture} ${city}${line1} ${line2}`
        },
        shortAddress(_, { readField }) {
          const postalCode =
            readField<Maybe<UserAddress['postalCode']>>('postalCode') ?? ''
          const prefecture =
            readField<Maybe<UserAddress['prefecture']>>('prefecture') ?? ''
          const city = readField<Maybe<UserAddress['city']>>('city') ?? ''
          const line1 = readField<Maybe<UserAddress['line1']>>('line1') ?? ''
          const line2 = readField<Maybe<UserAddress['line2']>>('line2') ?? ''

          // 郵便番号・都道府県・市区町村・番地がすべて入力されていなければ、shortAddressはnullとして返す
          if (!(postalCode && prefecture && city && line1)) return null

          return `${prefecture} ${city}${line1} ${line2}`
        },
      },
    },
    PostReply: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.formatRelative(createdAt) : null
        },
      },
    },
    Campaign: {
      fields: {
        formattedEndedAt(_, { readField }) {
          const endedAt = readField<number>('endedAt')
          return endedAt ? DateTime.format(endedAt) : null
        },
        formattedStartedAt(_, { readField }) {
          const startedAt = readField<number>('startedAt')
          return startedAt ? DateTime.format(startedAt) : null
        },
        openStatus: (_, { readField }) => {
          const open = readField<Campaign['open']>('open')
          const startedAt = readField<Campaign['startedAt']>('startedAt')

          return open
            ? 'open'
            : startedAt > DateTime.now()
            ? 'willOpen'
            : 'ended'
        },
        entryStatus: (_, { readField }) => {
          const isEntry = readField<Campaign['isEntry']>('isEntry')
          const startedAt = readField<Campaign['startedAt']>('startedAt')

          return isEntry
            ? 'isEntry'
            : startedAt > DateTime.now()
            ? 'canEntry'
            : 'canNotEntry'
        },
      },
    },
    UserCampaignRanking: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.format(createdAt) : null
        },
      },
    },
    ArtistCampaignRanking: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.format(createdAt) : null
        },
      },
    },
    UserGachaTicket: {
      fields: {
        formattedAvailable: (_, { readField }) => {
          const available =
            readField<Maybe<UserGachaTicket['available']>>('available') ?? '0'
          return formatNumber(available)
        },
      },
    },
    PurchasableGachaTicket: {
      fields: {
        formattedPrepaidPoint: (_, { readField }) => {
          return formatNumber(
            readField<Maybe<PurchasableGachaTicket['prepaidPoints']>>(
              'prepaidPoints'
            ) ?? ''
          )
        },
        formattedPrice: (_, { readField }) => {
          return formatNumber(
            readField<Maybe<PurchasableGachaTicket['price']>>('price') ?? ''
          )
        },
      },
    },
    PrepaidPointBalance: {
      fields: {
        formattedPrepaidPointBarance: (_, { readField }) => {
          const balance =
            readField<Maybe<PrepaidPointBalance['balance']>>('balance') ?? '0'
          return formatNumber(balance)
        },
      },
    },
    ReceptionPeriod: {
      fields: {
        formattedEndedAt(_, { readField }) {
          const endedAt = readField<number>('endedAt')
          return endedAt ? DateTime.format(endedAt) : null
        },
        formattedStartedAt(_, { readField }) {
          const startedAt = readField<number>('startedAt')
          return startedAt ? DateTime.format(startedAt) : null
        },
      },
    },
    Patron: {
      fields: {
        continueMonth(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          if (!createdAt) {
            return null
          }
          return DateTime.diff(createdAt, 'month', false)
        },
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.formatDate(createdAt) : null
        },
        formattedExpiredAt(_, { readField }) {
          const expiredAt = readField<number>('expiredAt')
          return expiredAt ? DateTime.formatDate(expiredAt) : null
        },
        formattedUpdatedAt(_, { readField }) {
          const updatedAt = readField<number>('updatedAt')
          return updatedAt ? DateTime.formatDate(updatedAt) : null
        },
      },
    },
    ArtistThreadChannel: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.formatRelative(createdAt) : null
        },
        isAccessible: {
          read(_, { readField, cache }) {
            const isPremium = readField('isPremium')
            const artistId = readField('artistId')

            const userData = cache.readQuery<{ user?: { id: string } }>({
              query: gql`
                query {
                  user {
                    id
                  }
                }
              `,
            })
            return !isPremium || userData?.user?.id === artistId
          },
        },
        comments: utoniqPagination(),
      },
    },
    Organization: {
      fields: {
        notifications: utoniqPagination(),
        campaigns: utoniqPagination(),
        missions: utoniqPagination(),
      },
    },
    Item: {
      fields: {
        serials: utoniqPagination(),
        tradableUsers: utoniqPagination(),
        comments: utoniqPagination(),
        formattedPrice(_, { readField }) {
          const price = readField<string>('price')
          return formatNumber(price)
        },
        formattedRemainingNumber(_, { readField }) {
          const issuedNumber = readField<number>('issuedNumber')
          const purchasedNumber = readField<number>('purchasedNumber') ?? 0
          const pendingPurchaseNumber =
            readField<number>('pendingPurchaseNumber') ?? 0
          if (!issuedNumber) {
            return '∞'
          }
          return formatNumber(
            issuedNumber - purchasedNumber - pendingPurchaseNumber
          )
        },
        buyOverLimit(_, { readField }) {
          const purchaseLimitByUser = readField<number>('purchaseLimitByUser')
          const purchasedByPointNumber = readField<number>(
            'purchasedByPointNumber'
          )
          return (
            !!purchaseLimitByUser &&
            !!purchasedByPointNumber &&
            purchaseLimitByUser <= purchasedByPointNumber
          )
        },
        formattedResaleOpenCount(_, { readField }) {
          const resaleOpenCount = readField<string>('resaleOpenCount') ?? 0
          return numeral(resaleOpenCount).format('0,0')
        },
        formattedResaleRaffleAppliedCount(_, { readField }) {
          const resaleRaffleAppliedCount =
            readField<string>('resaleRaffleAppliedCount') ?? 0
          return numeral(resaleRaffleAppliedCount).format('0,0')
        },
        status(_, { readField }) {
          const isPublished = readField<boolean>('isPublished')
          if (!isPublished) {
            return ItemStatus.Private
          }
          const salesStatus = readField<SalesStatus>('salesStatus')
          return salesStatus === SalesStatus.Active
            ? ItemStatus.Sale
            : ItemStatus.Pause
        },
        isDrm(_, { readField }) {
          let resource = readField<Resource | null>('resource')
          const type = readField<ItemType>('type')
          const thumbnail = readField<{ front: ResourceFragment }>('thumbnail')
          const thumbnailFront = readField<Resource>('front', thumbnail)
          const thumbnailDRM = readField<Resource['drm']>('drm', thumbnailFront)

          const isDrmThumbnail = thumbnailDRM?.encryptingStatus === 'encrypted'

          if (type === ItemType.Avatar) {
            const avatarResources =
              readField<AvatarResources>('avatarResources')
            resource = avatarResources?.avatarResource
          }
          if (type === ItemType.Wallpaper) {
            const wallpaperResources =
              readField<WallpaperResources>('wallpaperResources')
            resource = wallpaperResources?.wallpaperResource
          }

          return (
            isDrmThumbnail || resource?.drm?.encryptingStatus === 'encrypted'
          )
        },
        // アイテムの購入可能な残り枚数
        remainingNumber(_, { readField }) {
          const issuedNumber = readField<number>('issuedNumber') ?? 0
          const purchasedNumber = readField<number>('purchasedNumber') ?? 0
          const pendingPurchaseNumber =
            readField<number>('pendingPurchaseNumber') ?? 0

          return issuedNumber - purchasedNumber - pendingPurchaseNumber
        },
        // リセール応募期間前で、期間がくればリセール応募可能かどうか
        willResaleAvailable(_, { readField }) {
          const type = readField<ItemType>('type')
          const resaleEnabled = readField<Boolean>('resaleEnabled')
          const metadata = readField<ItemMetadata>('metadata')
          const issuedNumber = readField<number>('issuedNumber') || 0
          const purchasedNumber = readField<number>('purchasedNumber') || 0
          const pendingPurchaseNumber =
            readField<number>('pendingPurchaseNumber') || 0

          // チケットアイテムでなものはfalse
          if (type !== 'ticket') return false
          // リセール有効でないものはfalse
          if (!resaleEnabled) return false
          // リセール期間前ではないものはfalse
          if (new Date() > metadata?.ticket?.resalePeriod?.startedAt)
            return false
          // アイテム在庫があればfalse
          if (issuedNumber > purchasedNumber + (pendingPurchaseNumber ?? 0)) {
            return false
          }

          return true
        },
        isResaleEnable(_, { readField }) {
          const type = readField<ItemType>('type')
          const resaleEnabled = readField<Boolean>('resaleEnabled')
          const metadata = readField<ItemMetadata>('metadata')

          // チケットアイテムでなものはfalse
          if (type !== 'ticket') return false
          // リセール有効でないものはfalse
          if (!resaleEnabled) return false
          // リセール期間前ではないものはfalse
          if (
            DateTime.nowUnixMilliseconds() <
              metadata?.ticket?.resalePeriod?.startedAt ||
            DateTime.nowUnixMilliseconds() >
              metadata?.ticket?.resalePeriod?.endedAt
          ) {
            return false
          }

          return true
        },
        /**
         * skuId単位のSKUユニット情報を格納したもの
         * 実装は @/core/sku で行い、型付のためにskuSettings.units[0]を初期値として返す
         */
        sku(_, { readField }) {
          const skuSettings = readField<SkuSettings>('skuSettings')

          if (!skuSettings || !skuSettings?.units.length) return null

          return skuSettings?.units[0]
        },
      },
    },
    GachaTicketOrder: {
      fields: {
        title(_, { readField }) {
          const orderType =
            readField<Maybe<GachaTicketOrder['orderType']>>('orderType')
          switch (orderType) {
            case 'membership:reward':
              return 'membershipReward'
            case 'mission:reward':
              return 'missionReward'
            case 'gacha:roll':
              return 'gachaRoll'
            case 'admin:manual':
              return 'adminManual'
            case 'purchase':
              return 'purchase'
            case 'purchase:bulk':
              return 'purchaseBulk'
            case 'serial':
              return 'QRCodeReward'
            case 'login:bonus':
              return 'loginBonus'
          }
        },
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.format(createdAt) : null
        },
      },
    },
    Collection: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.formatDate(createdAt) : null
        },
        formattedStartedAt(_, { readField }) {
          const startedAt = readField<number>('startedAt')
          return startedAt ? DateTime.format(startedAt) : null
        },
        formattedEndedAt(_, { readField }) {
          const endedAt = readField<number>('endedAt')
          return endedAt ? DateTime.format(endedAt) : null
        },
        collectionItems: utoniqPagination(['label', 'firstLabel']),
      },
    },
    UserCollection: {
      fields: {
        totalOwnedItemCount(_, { readField }) {
          const ownedItems = readField<UserCollectionItem[]>('ownedItems') || []
          return ownedItems.filter(ownedItem => ownedItem.ownedCount).length
        },
      },
    },
    Business: {
      fields: {
        formattedBirthday(_, { readField }) {
          const birthday = readField<number>('birthday')
          return birthday ? DateTime.formatDate(birthday) : null
        },
        phone(phone) {
          if (!phone) {
            return ''
          }
          return parsePhoneNumber(phone).format('NATIONAL').replaceAll('-', '')
        },
      },
    },
    TradeNegotiation: {
      merge(_, incoming, { readField }) {
        const request = readField<TradeRequest>('request', incoming)
        const requestedByUser = readField('requestedByUser', request)
        const candidates = readField<TradeCandidate[]>('candidates', incoming)

        /**
         * @description hotfix bug TypeError: Cannot convert undefined or null to object
         */
        // // トレードに関わるユーザーが見つからない場合、emptyを返す
        // if (!requestedByUser || !candidates?.length) {
        //   return { __typename: 'TradeNegotiation' }
        // }

        return incoming
      },
      fields: {
        formattedExpiredAt(_, { readField }) {
          const expiredAt = readField<number>('expiredAt')
          return expiredAt ? DateTime.format(expiredAt) : null
        },
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.formatRelative(createdAt) : null
        },
        /**
         * @description
         * relayStylePaginationの内部エラーでmutation時にcommentsを指定するとエラーが発生する。
         * undefined is not an object (evaluating 'Object.keys(storeObject)')
         * Apollo-ClientのGithubでissue起票、反応待ち
         * https://github.com/apollographql/apollo-client/issues/10021#issue-1344086650
         */
        comments: utoniqPagination(),
      },
    },
    LoginBonus: {
      fields: {
        // bonusesから本日のログインボーナスを取得する
        todayBonus(_, { readField }) {
          const bonuses = readField<Maybe<BonusElementFragment[]>>('bonuses')
          const todayTargetDate =
            readField<Maybe<Scalars['Int']>>('todayTargetDate')
          const obtainedBonuses = readField<Maybe<Array<BonusElementFragment>>>(
            'obtainedBonuses',
            readField('loginBonusUser')
          )

          // 取得済みのボーナスを除外
          const availableBonuses = bonuses?.filter(
            ({ bonusKey }) =>
              !obtainedBonuses?.find(
                ({ bonusKey: _bonusKey }) => bonusKey === _bonusKey
              )
          )

          // bonusKeyがtodayTargetDateに一致するものを返す
          return (
            availableBonuses?.find(
              ({ bonusKey }) => bonusKey === String(todayTargetDate)
            ) ?? null
          )
        },
      },
    },
    Resource: {
      keyFields: ['uri'],
      fields: {
        smallUri: imageKitUri,
        mediumUri: imageKitUri,
        compressedUri: imageKitUri,
      },
    },
    Avatar: {
      keyFields: ['uri'],
      fields: {
        smallUri: imageKitUri,
        mediumUri: imageKitUri,
        compressedUri: imageKitUri,
      },
    },
    Image: {
      fields: {
        smallUri: imageKitUri,
        mediumUri: imageKitUri,
        compressedUri: imageKitUri,
      },
    },
    SalesReport: {
      fields: {
        formattedBalance(_, { readField }) {
          const balance = readField<number>('balance')
          return formatNumber(balance)
        },
        formattedTotalSales(_, { readField }) {
          const totalSales = readField<number>('totalSales')
          return formatNumber(totalSales)
        },
      },
    },
    totalSalesReportDetail: {
      fields: {
        formattedTotalSales(_, { readField }) {
          const totalSales = readField<number>('totalSales')
          return formatNumber(totalSales)
        },
      },
    },
    Last30DaysSalesReport: {
      fields: {
        formattedSalesWithoutFee(_, { readField }) {
          const salesWithoutFee = readField<number>('salesWithoutFee')
          return formatNumber(salesWithoutFee)
        },
      },
    },
    Transaction: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.formatDate(createdAt) : null
        },
        formattedBalance(_, { readField }) {
          const balance = readField<number>('balance')
          return formatNumber(balance)
        },
        formattedBalanceInPayout(_, { readField }) {
          const balanceInPayout = readField<number>('balanceInPayout')
          return formatNumber(balanceInPayout)
        },
        formattedTotalBalance: (_, { readField }) => {
          const balance = readField<number>('balance') ?? 0
          const balanceInPayout = readField<number>('balanceInPayout') ?? 0
          return formatNumber(balance + balanceInPayout)
        },
        formattedAmount: (_, { readField }) => {
          const amount = readField<number>('amount') ?? 0
          return formatNumber(amount)
        },
        formattedTotalBalanceNumber(_, { readField }) {
          const balance = readField<number>('balance') ?? 0
          const balanceInPayout = readField<number>('balanceInPayout') ?? 0
          return balance + balanceInPayout
        },
      },
    },
    NftToken: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.formatDate(createdAt) : null
        },
      },
    },
    LiveStream: {
      fields: {
        isAccessible: {
          read(_, { readField, cache }) {
            const isPremium = readField('isPremium')
            const patron = readField('patron', readField('artist'))
            const artistId = readField('id', readField('artist'))

            const userData = cache.readQuery<{
              user?: {
                id: string
              }
            }>({
              query: gql`
                query {
                  user {
                    id
                  }
                }
              `,
            })
            return !isPremium || !!patron || userData?.user?.id === artistId
          },
        },
        liveStreamDuration(_, { readField }) {
          const startedAt = readField<number>('startedAt')
          const endedAt = readField<number>('endedAt')
          return startedAt && endedAt
            ? DateTime.durationDate(startedAt, endedAt).format('HH:mm:ss')
            : null
        },
        formattedLikeCount(_, { readField }) {
          const likeCount = readField<number>('likeCount') ?? 0
          return formatNumber(likeCount)
        },
        formattedCommentCount(_, { readField }) {
          const commentCount = readField<number>('commentCount') ?? 0
          return formatNumber(commentCount)
        },
        formattedSubscriberCount(_, { readField }) {
          const subscriberCount = readField<number>('subscriberCount') ?? 0
          return formatNumber(subscriberCount)
        },
        formattedTipTotal(_, { readField }) {
          const tipTotal = readField<number>('tipTotal') ?? 0
          return formatNumber(tipTotal)
        },
      },
    },
    OrganizationNotification: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.format(createdAt) : null
        },
        formattedPublishedAt(_, { readField }) {
          const publishedAt = readField<number>('publishedAt')
          return publishedAt ? DateTime.format(publishedAt) : null
        },
      },
    },
    RankingPeriod: {
      fields: {
        rankings: utoniqPagination(),
      },
    },
    TicketStatus: {
      fields: {
        formattedExpiredAt(_, { readField }) {
          const expiredAt = readField<number>('expiredAt')
          return expiredAt ? DateTime.format(expiredAt) : null
        },
        formattedUsedAt(_, { readField }) {
          const usedAt = readField<number>('usedAt')
          return usedAt ? DateTime.format(usedAt) : null
        },
        canUseTicket(_, { readField }) {
          const expiredAt = readField<number>('expiredAt')
          const isUsed = readField<boolean>('isUsed')
          if (!expiredAt) {
            return !isUsed
          }
          return !isUsed && expiredAt > DateTime.nowUnixMilliseconds()
        },
        isExpired(_, { readField }) {
          const expiredAt = readField<number>('expiredAt')
          if (!expiredAt) {
            return false
          }
          return expiredAt < DateTime.nowUnixMilliseconds()
        },
      },
    },
    MembershipSubscriptionPlan: {
      fields: {
        patrons: utoniqPagination(['planId']),
        /** ユーザーのサブスクリプション継続月 */
        month: {
          read(_, { readField, cache, variables }) {
            const planId = readField<string>('planId')
            const data = cache.readQuery<{
              artist: {
                patron?: {
                  formattedCreatedAt: string
                  subscription: {
                    planId: string
                  }
                }
              }
            }>({
              query: gql`
                query artistPlanMonth($artistId: ID!) {
                  artist(id: $artistId) {
                    id
                    patron {
                      id
                      formattedCreatedAt
                      subscription {
                        planId
                      }
                    }
                  }
                }
              `,
              variables: {
                artistId: variables?.id ?? '',
              },
            })

            if (!data?.artist.patron) return 0

            const userPlan = data?.artist.patron.subscription

            return userPlan?.planId === planId
              ? DateTime.diff(
                  data?.artist.patron?.formattedCreatedAt ?? null,
                  'month'
                ) || 0
              : 0
          },
        },
        /* ユーザーから見たプランのステータス */
        planRelationship: {
          read(_, { readField, cache, variables }) {
            const amount = readField<number>('amount') ?? 0
            const planId = readField<string>('planId')

            const data = cache.readQuery<{
              artist: {
                pendingPurchasePatron?: {
                  status: PendingPurchaseItemStatus
                  metadata?: PendingPurchaseItemMetadata
                }
                subscription?: ArtistMembershipSubscriptionDetailFragment['subscription']
                patron?: {
                  downgradeAtNextPeriod: boolean
                  cancelAtPeriodEnd: boolean
                  paymentMethod: PaymentMethod
                  subscription: {
                    planId: string
                    amount: number
                    downgradePlanId: string
                  }
                }
              }
            }>({
              query: gql`
                query artistPatronPlan($artistId: ID!) {
                  artist(id: $artistId) {
                    id
                    subscription {
                      products {
                        productId
                        plans {
                          planId
                          amount
                        }
                      }
                    }
                    pendingPurchasePatron {
                      id
                      artistId
                      status
                      metadata {
                        membership {
                          planId
                        }
                      }
                    }
                    patron {
                      id
                      downgradeAtNextPeriod
                      cancelAtPeriodEnd
                      paymentMethod
                      subscription {
                        planId
                        amount
                        downgradePlanId
                      }
                    }
                  }
                }
              `,
              variables: {
                artistId: variables?.id ?? '',
              },
            })

            if (
              data?.artist.pendingPurchasePatron?.metadata?.membership?.planId
            ) {
              const pendingPurchasePatron = data?.artist.pendingPurchasePatron
              const productActive = getActiveMembershipSubscriptionProductPlan(
                data?.artist,
                pendingPurchasePatron?.metadata?.membership?.planId
              )
              if (
                pendingPurchasePatron?.status ===
                  PendingPurchaseItemStatus.WaitingPayment &&
                pendingPurchasePatron.metadata?.membership?.planId === planId
              ) {
                return 'konbini_waitingPayment'
              }

              if (
                pendingPurchasePatron.metadata?.membership?.planId === planId
              ) {
                return 'konbini_joined'
              }

              return amount > (productActive?.plan?.amount ?? 0)
                ? 'konbini_notDowngradingUpper'
                : 'konbini_notDowngradingLower'
            }

            // Patronドキュメントがない場合、「未入会」を返す
            if (!data?.artist.patron) return 'unjoined'

            const userPlan = data?.artist.patron?.subscription
            const prefix =
              data?.artist.patron.paymentMethod === PaymentMethod.Konbini
                ? 'konbini_'
                : ''
            const downgradeAtNextPeriod =
              !!data?.artist.patron.downgradeAtNextPeriod

            /**
             * メンバーシップ未登録時のステータス
             */
            // メンバーシップ登録をしてない場合、'未入会'を返す
            if (!userPlan) return 'unjoined'

            /**
             * 更新停止中のステータス
             */
            if (data?.artist.patron.cancelAtPeriodEnd) {
              // サブスクの更新停止中で、そのプランに加入している場合

              // '停止予定'
              if (userPlan.planId === planId) return 'stopping'

              // '他のプランが停止予定'
              return amount > userPlan?.amount
                ? 'stoppingUpper'
                : 'stoppingLower'
            }

            /**
             * ダウングレード中のステータス
             */
            if (downgradeAtNextPeriod) {
              // downgradePlanIdとプランIdが一致する場合は'入会予定'を返す
              if (userPlan.downgradePlanId === planId)
                return amount > userPlan?.amount
                  ? 'forJoinedUpper'
                  : 'forJoinedLower'
              // 現在のプランIdと一致する場合は、'離脱予定'を返す
              if (userPlan.planId === planId) return 'forLeave'
              // それ以外の場合'ダウングレード関係ではない'を返す
              return amount > userPlan?.amount
                ? 'notDowngradingUpper'
                : 'notDowngradingLower'
            }

            /**
             * メンバーシップ登録中のステータス
             */
            // ユーザーの加入プランよりも上位の場合、'上位プラン'を返す
            if (amount > userPlan?.amount) return `${prefix}upper`
            // ユーザーの加入プランよりも下位の場合、'下位プラン'を返す
            if (amount < userPlan?.amount) return `${prefix}lower`
            // それ以外の場合'入会中'を返す
            return 'joined'
          },
        },
        formattedAmount(_, { readField }) {
          const amount = readField<number>('amount') ?? 0
          return formatNumber(amount)
        },
      },
    },
    ResaleItem: {
      fields: {
        formattedListedAt(_, { readField }) {
          const listedAt = readField<number>('listedAt')
          return listedAt ? DateTime.format(listedAt) : null
        },
        formattedTotalItemPrice(_, { readField }) {
          const totalItemPrice = readField<number>('totalItemPrice') ?? 0
          return numeral(totalItemPrice).format('0,0')
        },

        formattedPaymentExpiredAt(_, { readField }) {
          const paymentExpiredAt = readField<number>('paymentExpiredAt')
          return paymentExpiredAt ? DateTime.format(paymentExpiredAt) : null
        },
      },
    },
    ItemResaleRaffleUser: {
      fields: {
        formattedCreatedAt(_, { readField }) {
          const createdAt = readField<number>('createdAt')
          return createdAt ? DateTime.format(createdAt) : null
        },
        formattedUpdatedAt(_, { readField }) {
          const updatedAt = readField<number>('updatedAt')
          return updatedAt ? DateTime.format(updatedAt) : null
        },
        formattedTotalItemPrice(_, { readField }) {
          const totalItemPrice = readField<number>('totalItemPrice') ?? 0
          return numeral(totalItemPrice).format('0,0')
        },
      },
    },
    PendingPurchaseItem: {
      fields: {
        totalPrice(_, { readField }) {
          const items = readField<PendingPurchaseItem['items']>('items')
          if (!items) return 0

          return items.reduce((acc, crr) => {
            const price = readField<number>('price', crr.item) ?? 0
            if (!crr.skus?.length) {
              return crr.count * price + acc
            }
            return crr.skus.reduce((total, userCartItemSku) => {
              const skuPrice =
                readField<number>('price', userCartItemSku.sku) ?? price
              return total + userCartItemSku.count * skuPrice
            }, 0)
          }, 0)
        },
        formattedTotalPrice(_, { readField }) {
          const totalPrice =
            readField<PendingPurchaseItem['totalPrice']>('totalPrice')
          return formatNumber(totalPrice)
        },
        formattedExpiredAt(_, { readField }) {
          const expiredAt = readField<number>('expiredAt')
          return expiredAt ? DateTime.format(expiredAt) : null
        },
      },
    },
    SkuUnit: {
      fields: {
        remainingNumber(_, { readField }) {
          const stock = readField<number>('stock')
          const purchasedNumber = readField<number>('purchasedNumber') ?? 0
          const pendingPurchaseNumber =
            readField<number>('pendingPurchaseNumber') ?? 0

          return stock ? stock - purchasedNumber - pendingPurchaseNumber : null
        },
        formattedPrice(_, { readField }) {
          const price = readField<Maybe<number>>('price')
          return formatNumber(price ?? '')
        },
      },
    },
    UserCart: {
      fields: {
        totalPrice(_, { readField }) {
          const items = readField<UserCart['items']>('items')
          if (!items) return 0
          return items.reduce((acc, crr) => {
            const price = readField<number>('price', crr.item) ?? 0
            if (!crr.skus?.length) {
              return crr.count * price + acc
            }

            return (
              crr.skus.reduce((total, userCartItemSku) => {
                const skuPrice =
                  readField<number>('price', userCartItemSku.sku) ?? price
                return total + userCartItemSku.count * skuPrice
              }, 0) + acc
            )
          }, 0)
        },
        formattedTotalPrice(_, { readField }) {
          const totalPrice = readField<UserCart['totalPrice']>('totalPrice')
          return formatNumber(totalPrice)
        },
      },
    },
    UserCartItem: {
      fields: {
        totalPrice(_, { readField }) {
          const item = readField<UserCartItem['item']>('item')
          const price = readField<number>('price', item) ?? 0
          const count = readField<UserCartItem['count']>('count') ?? 0
          if (!item) return 0
          const skus = readField<UserCartItemSku[]>('skus')
          if (skus?.length) {
            return skus.reduce((total, userCartItemSku) => {
              const skuPrice =
                readField<number>('price', userCartItemSku.sku) ?? price
              return total + userCartItemSku.count * skuPrice
            }, 0)
          }

          return price * count
        },
        formattedTotalPrice(_, { readField }) {
          const totalPrice = readField<UserCartItem['totalPrice']>('totalPrice')
          return formatNumber(totalPrice)
        },
      },
    },
    UserCartTransactionOrder: {
      fields: {
        formattedTotalAmount(_, { readField }) {
          const totalAmount =
            readField<UserCartTransactionOrder['totalAmount']>('totalAmount')
          return formatNumber(totalAmount)
        },
        formattedCreatedAt(_, { readField }) {
          const createdAt =
            readField<UserCartTransactionOrder['createdAt']>('createdAt')
          return createdAt ? DateTime.formatDate(createdAt) : null
        },
        formattedSaleProfit(_, { readField }) {
          const totalAmountWithoutFee =
            readField<number>('totalAmountWithoutFee') ?? 0
          const storeFee = readField<number>('storeFee') ?? 0
          const checkoutFees = readField<CheckoutFees>('checkoutFees')
          const shippingFee = checkoutFees?.shipping?.fee ?? 0
          return formatNumber(totalAmountWithoutFee + shippingFee - storeFee)
        },
        formattedStoreFee(_, { readField }) {
          const storeFee = readField<number>('storeFee') ?? 0
          return formatNumber(storeFee)
        },
        formattedTotalAmountWithoutFee(_, { readField }) {
          const totalAmountWithoutFee =
            readField<number>('totalAmountWithoutFee') ?? 0
          return formatNumber(totalAmountWithoutFee)
        },
        originalTotalAmount(_, { readField }) {
          const totalAmount =
            readField<UserCartTransactionOrder['totalAmount']>('totalAmount') ||
            0
          const checkoutFees =
            readField<UserCartTransactionOrder['checkoutFees']>(
              'checkoutFees'
            ) ?? undefined
          const totalFee =
            readField<CheckoutFees['totalFee']>('totalFee', checkoutFees) || 0
          return totalAmount - totalFee
        },
        formattedOriginalTotalAmount(_, { readField }) {
          const totalAmount =
            readField<UserCartTransactionOrder['totalAmount']>('totalAmount') ||
            0
          const checkoutFees =
            readField<UserCartTransactionOrder['checkoutFees']>(
              'checkoutFees'
            ) ?? undefined
          const totalFee =
            readField<CheckoutFees['totalFee']>('totalFee', checkoutFees) || 0
          return formatNumber(totalAmount - totalFee)
        },
      },
    },
    PickupUser: {
      fields: {
        isExpired(_, { readField }) {
          const pickupTime = readField<number>('pickupTime')
          const isCompleted = readField<boolean>('isCompleted')
          const eventCollection = readField<EventCollection>('eventCollection')
          const validMinutes = readField<EventCollection['validMinutes']>(
            'validMinutes',
            eventCollection
          )

          if (isCompleted) return false
          if (!pickupTime || !validMinutes) return false

          return (
            dayjs(pickupTime).add(validMinutes, 'minute').unix() <
            dayjs().unix()
          )
        },
        formattedPickupTime(_, { readField }) {
          const pickupTime = readField<number>('pickupTime')
          return pickupTime ? DateTime.format(pickupTime) : null
        },
        formattedPickupStartEndTime(_, { readField }) {
          const pickupTime = readField<number>('pickupTime')
          const eventCollection = readField<EventCollection>('eventCollection')
          const validMinutes = readField<EventCollection['validMinutes']>(
            'validMinutes',
            eventCollection
          )

          if (!validMinutes) {
            return pickupTime ? DateTime.format(pickupTime) : null
          }
          return pickupTime
            ? DateTime.formatStartEndTime(pickupTime, validMinutes)
            : null
        },
        formattedCompletedAt(_, { readField }) {
          const completedAt = readField<number>('completedAt')
          return completedAt ? DateTime.format(completedAt) : null
        },
        /** pickupUser時間単位のcartTransactionOrderのデータを集計して返す */
        pickupOrder(_, { readField }) {
          const cartTransactionOrders = readField<UserCartTransactionOrder[]>(
            'cartTransactionOrders'
          )

          if (!cartTransactionOrders) return null

          const orders = cartTransactionOrders
            .map(
              cartTransactionOrder =>
                cartTransactionOrder.itemOrders ??
                readField('itemOrders', cartTransactionOrder)
            )
            .flat()

          // キャンセル済みシリアル
          const canceledSerialIds = orders
            .map(
              order =>
                order?.canceledSerialIds ??
                readField('canceledSerialIds', order)
            )
            .flat()

          const pickupOrder =
            orders.reduce((acc, currentItem) => {
              const itemId = readField<string>(
                'id',
                readField('item', currentItem)
              )
              const title =
                readField<string>('title', readField('item', currentItem)) ?? ''
              const serialIds =
                readField<string[]>('serialIds', currentItem) ?? []
              const serials = readField<string[]>('serials', currentItem) ?? []

              if (!itemId || !serialIds) return acc

              const existingItem = acc.find(item => item.itemId === itemId)

              if (existingItem) {
                existingItem.serialIds.push(...serialIds)
                existingItem.serials.push(...serials)
              } else {
                acc.push({
                  itemId,
                  title,
                  serialIds: [...serialIds],
                  count: serialIds.length ?? 1,
                  serials: [...serials],
                })
              }

              return acc
            }, [] as { itemId: string; serialIds: string[]; title: string; count: number; serials: any[] }[]) ??
            []

          // キャンセル済みシリアルを除外
          const _pickupOrder = pickupOrder
            .map(order => {
              const filteredSerialIds = order.serialIds.filter(
                serialId => !canceledSerialIds.includes(serialId)
              )

              return {
                ...order,
                count: filteredSerialIds.length ?? 0,
                serialIds: filteredSerialIds,
                serials: order.serials.filter(serial =>
                  filteredSerialIds.includes(
                    readField<string>('id', serial) as string
                  )
                ),
              }
            })
            .filter(order => !!order.count)

          const userItemIds =
            readField<PickupUser['userItemIds']>('userItemIds')

          // 受取時間のアイテムのみを抽出
          const __pickupOrder = _pickupOrder.filter(order =>
            userItemIds?.includes(readField<string>('itemId', order) ?? '')
          )

          return __pickupOrder
        },
        // 購入アイテムの名前(個数)を繋げた文字列
        itemsString(_, { readField }) {
          const pickupOrder =
            readField<PickupUser['pickupOrder']>('pickupOrder')
          if (!pickupOrder) return ''

          return pickupOrder
            .map(({ title, count, serials }) => {
              // シリアル毎のSKUを取得
              const skuNamesArray = serials
                .map(serial => {
                  const sku = readField<SkuSettings>('sku', serial)

                  const skuName = readField<string>('unitName', sku)

                  return skuName
                })
                .filter(skuName => !!skuName)

              // SKUがない場合 「アイテム名 x 個数」の表記で返す
              if (!skuNamesArray.length) return `${title}×${count}`

              // SKUのある場合、SKUユニット単位ごとに名前と個数を返す
              const skuArray = Object.entries(
                serials.reduce((acc, serial) => {
                  const sku = readField<SkuSettings>('sku', serial)
                  const unitName = readField<string>('unitName', sku)
                  if (unitName) {
                    acc[unitName] = (acc[unitName] || 0) + 1
                  }
                  return acc
                }, {} as Record<string, number>)
              ).map(([unitName, count]) => ({
                unitName,
                count,
              }))

              return skuArray
                .map(({ unitName, count }) => `${title}(${unitName})×${count}`)
                .join('、')
            })
            .join('、')
        },
        // 受け取り開始/終了までの表示
        pickupRemainingTime(_, { readField }) {
          const isCompleted = readField<boolean>('isCompleted')
          // 受け取り済みの場合は表示しない
          if (isCompleted) return null

          const pickupTime = readField<number>('pickupTime')
          const validMinutes = readField<number>(
            'validMinutes',
            readField('eventCollection')
          )

          if (!pickupTime || !validMinutes) return null
          const now = dayjs().valueOf()
          const expiredTime = dayjs(pickupTime)
            .add(validMinutes, 'minute')
            .valueOf()

          if (
            // 受け取り期限を過ぎた場合は表示しない
            now > expiredTime ||
            // 24時間以上先の場合は表示しない
            (now < pickupTime && dayjs(pickupTime).diff(now, 'hour') > 24)
          )
            return null

          // 受取開始時間を過ぎているかどうか
          const expiring = now > pickupTime

          const remainingMinutes =
            (DateTime.diff(
              expiring ? expiredTime : pickupTime,
              'minute',
              true
            ) || 0) * -1

          const hours = Math.floor(remainingMinutes / 60)
          const minutes = Math.round(remainingMinutes) % 60

          return { hours, minutes, expiring }
        },
        // pickupUser時間単位ごとの、チケットを使用する際の引数 { itemId, serilIds } の値
        ticketItemSerialsInput(_, { readField }) {
          const cartTransactionOrders = readField<UserCartTransactionOrder[]>(
            'cartTransactionOrders'
          )

          if (!cartTransactionOrders) return null

          const orders = cartTransactionOrders
            .map(
              cartTransactionOrder =>
                cartTransactionOrder.itemOrders ??
                readField('itemOrders', cartTransactionOrder)
            )
            .flat()

          if (!orders) return []

          // キャンセル済みシリアル
          const canceledSerialIds = orders
            .map(
              order =>
                order?.canceledSerialIds ??
                readField('canceledSerialIds', order)
            )
            .flat()

          const items = orders.reduce((acc, currentItem) => {
            const serialIds = readField<string[]>('serialIds', currentItem)
            const item = readField<Item>('item', currentItem)
            if (!item) return acc

            const itemId = readField<string>('id', item)

            if (!serialIds || !itemId) return acc

            const existingItem = acc.find(_item => _item.itemId === itemId)

            // キャンセルされたシリアルをシリアル一覧から除外
            const actualSerialIds = serialIds.filter(
              serialId => !canceledSerialIds.includes(serialId)
            )
            // 対応するシリアルがなければitemIdも除外
            if (!actualSerialIds.length) return acc

            existingItem
              ? existingItem.serialIds.push(...actualSerialIds)
              : acc.push({
                  itemId,
                  serialIds: [...actualSerialIds],
                })

            return acc
          }, [] as { itemId: string; serialIds: string[] }[])

          return items
        },
        // キャンセル分を差し引いたしたトータルの金額
        actualTotalAmount(_, { readField }) {
          const cartTransactionOrders = readField<UserCartTransactionOrder[]>(
            'cartTransactionOrders'
          )

          if (!cartTransactionOrders?.length) return 0

          const originalTotalAmount = cartTransactionOrders.reduce(
            (acc, cartTransactionOrder) => {
              const totalAmount =
                readField<number>('totalAmount', cartTransactionOrder) ?? 0
              return acc + totalAmount
            },
            0
          )

          const totalRefundedAmount = cartTransactionOrders.reduce(
            (acc, cartTransactionOrder) => {
              const cancels = readField<{ refundedAmount: number }[]>(
                'cancels',
                cartTransactionOrder
              )

              return (
                acc +
                (cancels?.reduce((acc, cancel) => {
                  const refundedAmount =
                    readField<number>('refundedAmount', cancel) ?? 0

                  return acc + refundedAmount
                }, 0) ?? 0)
              )
            },
            0
          )

          return originalTotalAmount - totalRefundedAmount
        },
        formattedActualTotalAmount(_, { readField }) {
          const amount = readField<number>('actualTotalAmount') ?? 0
          return formatNumber(amount)
        },
      },
    },
    Event: {
      fields: {
        eventCollections: utoniqPagination(),
        /** 開催期間からイベント非開催日配列にあたる日付を除外した文字列を返す */
        dateRange(_, { readField }) {
          const START_DATE_FORMAT = 'YYYY/MM/DD（dd）'
          let END_DATE_FORMAT = 'DD（dd）'
          let CLOSE_DATE_FORMAT = 'DD（dd）'
          const startedAt = readField<Event['startedAt']>('startedAt') ?? null
          const endedAt = readField<Event['endedAt']>('endedAt') ?? null
          const closeDate = readField<Event['closeDate']>('closeDate') ?? []
          const minDate = Math.min(
            ...[startedAt, ...closeDate, endedAt].filter(date => !!date)
          )
          const maxDate = Math.max(
            ...[startedAt, ...closeDate, endedAt].filter(date => !!date)
          )

          if (!startedAt && !endedAt) return ''

          // check diff month
          if (
            minDate &&
            maxDate &&
            DateTime.formatDate(maxDate, 'MM') !==
              DateTime.formatDate(minDate, 'MM')
          ) {
            END_DATE_FORMAT = 'MM/DD（dd）'
            CLOSE_DATE_FORMAT = 'MM/DD（dd）'
          }
          // check is diff year
          if (
            maxDate &&
            minDate &&
            DateTime.formatDate(maxDate, 'YYYY') !==
              DateTime.formatDate(minDate, 'YYYY')
          ) {
            END_DATE_FORMAT = 'YYYY/MM/DD（dd）'
            CLOSE_DATE_FORMAT = 'MM/DD（dd）'
          }

          return getDateRange(startedAt, endedAt, closeDate, {
            startedAtTemplate: START_DATE_FORMAT,
            endedAtTemplate: END_DATE_FORMAT,
            closeDateTemplate: CLOSE_DATE_FORMAT,
          })
        },
        /** 開始日〜終了日をM/D形式で表示する。管理画面で利用 */
        aboutDateRange(_, { readField }) {
          let START_DATE_FORMAT = 'MM/DD'
          let END_DATE_FORMAT = 'DD'
          const CLOSE_DATE_FORMAT = 'MM/DD'
          const startedAt = readField<Event['startedAt']>('startedAt') ?? null
          const endedAt = readField<Event['endedAt']>('endedAt') ?? null
          const closeDate = readField<Event['closeDate']>('closeDate') ?? []
          const now = DateTime.nowUnixMilliseconds()
          const minDate = Math.min(
            ...[startedAt, ...closeDate, endedAt].filter(date => !!date)
          )
          const maxDate = Math.max(
            ...[startedAt, ...closeDate, endedAt].filter(date => !!date)
          )

          if (!startedAt && !endedAt) return ''

          // check diff month
          if (
            minDate &&
            maxDate &&
            DateTime.formatDate(maxDate, 'MM') !==
              DateTime.formatDate(minDate, 'MM')
          ) {
            END_DATE_FORMAT = 'MM/DD'
          }
          // check is diff year
          if (
            maxDate &&
            minDate &&
            DateTime.formatDate(maxDate, 'YYYY') !==
              DateTime.formatDate(minDate, 'YYYY')
          ) {
            START_DATE_FORMAT = 'YYYY/MM/DD'
            END_DATE_FORMAT = 'YYYY/MM/DD'
          }
          // check start is next year
          if (
            startedAt &&
            DateTime.formatDate(now, 'YYYY') !==
              DateTime.formatDate(startedAt, 'YYYY')
          ) {
            START_DATE_FORMAT = 'YYYY/MM/DD'
            END_DATE_FORMAT = 'MM/DD'
          }
          // check end is next year
          if (
            !startedAt &&
            endedAt &&
            DateTime.formatDate(now, 'YYYY') !==
              DateTime.formatDate(endedAt, 'YYYY')
          ) {
            START_DATE_FORMAT = 'MM/DD'
            END_DATE_FORMAT = 'YYYY/MM/DD'
          }

          return getDateRange(startedAt, endedAt, closeDate, {
            startedAtTemplate: START_DATE_FORMAT,
            endedAtTemplate: END_DATE_FORMAT,
            closeDateTemplate: CLOSE_DATE_FORMAT,
          })
        },
        /** すべての開催日を配列で取得する */
        dateArray(_, { readField }) {
          const startedAt = readField<number>('startedAt')
          const endedAt = readField<number>('endedAt')
          const closeDate = readField<number[]>('closeDate')

          if (!startedAt || !endedAt) return []

          const format = (d: number) => DateTime.formatDate(d, 'YYYY/MM/DD')

          // 開催期間のタイムスタンプの配列を取得
          const rangeDates: number[] = Array.from({
            length: (endedAt - startedAt) / (24 * 60 * 60 * 1000) + 1,
          })
            .map((_, idx) => startedAt + idx * 24 * 60 * 60 * 1000)
            .sort((a, b) => a - b)

          // 非開催日の文字列の配列を取得
          const closedDaysSet = new Set((closeDate ?? []).map(format))

          // 閉じられていない日付だけをフィルタリング
          return rangeDates
            .map(format)
            .filter(dateStr => !closedDaysSet.has(dateStr))
        },
        preSales: utoniqPagination(),
        formattedStartedAt(_, { readField }) {
          const startedAt = readField<number>('startedAt')
          return startedAt ? DateTime.formatDate(startedAt) : null
        },
        formattedEventTime(_, { readField }) {
          const startedAt = readField<Event['startedAt']>('startedAt')
          const endedAt = readField<Event['endedAt']>('endedAt')
          if (!startedAt && !endedAt) return ''
          return [
            DateTime.formatDate(startedAt, 'HH:mm'),
            DateTime.formatDate(endedAt, 'HH:mm'),
          ].join('〜')
        },
      },
    },
    Fee: {
      fields: {
        collectionFee() {
          return null
        },
        formattedFee(_, { readField }) {
          const fee = readField<number>('fee') ?? 0
          return formatNumber(fee)
        },
      },
    },
    ArtistCustomer: {
      fields: {
        formattedLastPurchasedAt(_, { readField }) {
          const lastPurchasedAt = readField<number>('lastPurchasedAt')
          return lastPurchasedAt ? DateTime.formatDate(lastPurchasedAt) : null
        },
        formattedPurchaseCount(_, { readField }) {
          const purchaseCount = readField<number>('purchaseCount') ?? 0
          return formatNumber(purchaseCount)
        },
      },
    },
    PreSale: {
      fields: {
        preSaleStatus(_, { readField }) {
          const type = readField<PreSale['type']>('type')
          // 抽選の場合
          if (type === 'lottery') {
            const now = new Date()
            const lottery = readField<PreSale['lottery']>('lottery')

            const applicationStartedAt = new Date(
              readField('applicationStartedAt', lottery ?? {}) ?? now
            )
            const applicationEndedAt = new Date(
              readField('applicationEndedAt', lottery ?? {}) ?? now
            )
            if (now >= applicationStartedAt && now <= applicationEndedAt) {
              return PreSaleStatus.Accepting
            } else if (now < applicationStartedAt) {
              return PreSaleStatus.BeforeReceiption
            }
            return PreSaleStatus.Expired
          }
          // 先着の場合
          if (readField<boolean>('isSoldOut')) {
            return PreSaleStatus.SoldOut
          }
          const now = new Date()
          const firstComeFirstServed = readField<
            PreSale['firstComeFirstServed']
          >('firstComeFirstServed')
          const purchasableStartedAt = new Date(
            readField('purchasableStartedAt', firstComeFirstServed ?? {}) ?? now
          )
          const purchasableEndedAt = new Date(
            readField('purchasableEndedAt', firstComeFirstServed ?? {}) ?? now
          )

          if (now >= purchasableStartedAt && now <= purchasableEndedAt) {
            return PreSaleStatus.Accepting
          } else if (now < purchasableStartedAt) {
            return PreSaleStatus.BeforeReceiption
          }
          return PreSaleStatus.Expired
        },
        formattedEndedAt(_, { readField }) {
          const endedAt = readField<number>('endedAt')
          return endedAt ? DateTime.format(endedAt) : null
        },
        formattedStartedAt(_, { readField }) {
          const startedAt = readField<number>('startedAt')
          return startedAt ? DateTime.format(startedAt) : null
        },
      },
    },
    PreSaleLottery: {
      fields: {
        formattedApplicationStartedAt(_, { readField }) {
          const applicationStartedAt = readField<number>('applicationStartedAt')
          return applicationStartedAt
            ? DateTime.format(applicationStartedAt)
            : null
        },
        formattedApplicationEndedAt(_, { readField }) {
          const applicationEndedAt = readField<number>('applicationEndedAt')
          return applicationEndedAt ? DateTime.format(applicationEndedAt) : null
        },
        formattedPurchasableStartedAt(_, { readField }) {
          const purchasableStartedAt = readField<number>('purchasableStartedAt')
          return purchasableStartedAt
            ? DateTime.format(purchasableStartedAt)
            : null
        },
        formattedPurchasableEndedAt(_, { readField }) {
          const purchasableEndedAt = readField<number>('purchasableEndedAt')
          return purchasableEndedAt ? DateTime.format(purchasableEndedAt) : null
        },
        formattedResultNoticedAt(_, { readField }) {
          const resultNoticedAt = readField<number>('resultNoticedAt')
          return resultNoticedAt ? DateTime.format(resultNoticedAt) : null
        },
      },
    },
    PreSaleFirstComeFirstServed: {
      fields: {
        formattedPurchasableStartedAt(_, { readField }) {
          const purchasableStartedAt = readField<number>('purchasableStartedAt')
          return purchasableStartedAt
            ? DateTime.format(purchasableStartedAt)
            : null
        },
        formattedPurchasableEndedAt(_, { readField }) {
          const purchasableEndedAt = readField<number>('purchasableEndedAt')
          return purchasableEndedAt ? DateTime.format(purchasableEndedAt) : null
        },
      },
    },
    PreSaleLotteryApplicant: {
      fields: {
        resultNoticedAt(resultNoticedAt, { readField }) {
          const preSale = readField<PreSale>('preSale')
          const lottery = readField<PreSale['lottery']>('lottery', preSale)

          return (
            resultNoticedAt ??
            (lottery ? readField('resultNoticedAt', lottery) : null)
          )
        },
        formattedAppliedAt(_, { readField }) {
          const appliedAt = readField<number>('appliedAt')
          return appliedAt
            ? DateTime.formatDate(appliedAt, 'YYYY/MM/DD HH:mm:ss')
            : null
        },
      },
    },
    TicketStatusThroughTicket: {
      fields: {
        formattedUsedAt(_, { readField }) {
          const usedAt = readField<number>('usedAt')
          return usedAt ? DateTime.format(usedAt) : null
        },
      },
    },
    TicketDistributionLink: {
      fields: {
        formattedExpiredAt(_, { readField }) {
          const expiredAt = readField<number>('expiredAt')
          return expiredAt
            ? DateTime.formatDate(expiredAt, 'YYYY/MM/DD HH:mm:ss')
            : null
        },
        isExpired(_, { readField }) {
          const expiredAt = readField<number>('expiredAt')
          if (!expiredAt) {
            return false
          }
          return expiredAt < DateTime.nowUnixMilliseconds()
        },
      },
    },
    ItemMetadataDetailTicket: {
      fields: {
        isTicketDistributionEnabled(_, { readField }) {
          const distributionEnabled = readField<boolean>('distributionEnabled')
          const distributionEndedAt = readField<number>('distributionEndedAt')
          if (distributionEnabled === false) {
            return false
          }
          if (!distributionEndedAt) {
            return distributionEnabled
          }
          return distributionEndedAt >= DateTime.nowUnixMilliseconds()
        },
      },
    },
    MultiItemSerial: {
      fields: {
        isExpired(_, { readField }) {
          const expiredAt = readField<number>('expiredAt')
          if (!expiredAt) {
            return false
          }
          return expiredAt < DateTime.nowUnixMilliseconds()
        },
      },
    },
    ItemMetadataDetail: {
      keyFields: false,
    },
  },
})

export function parseVariables<T extends {}>(
  storeFieldName: string
): T | undefined {
  try {
    const [, ...stringObject] = storeFieldName.split(':')
    return JSON.parse(stringObject.join(':'))
  } catch (e) {
    return
  }
}

/**
 * format phone number
 * @param phone
 * @example
 * formatPhoneNumber('1234567890') -> 12-3456-7890
 */
export const formatPhoneNumber = (phone?: string | number): string => {
  if (!phone) return ''
  return phone.toString().replace(/(\d*)(\d{4})(\d{4})/, '$1-$2-$3')
}

export const formatNumber = (price: number | string | undefined) => {
  return numeral(price ?? 0).format('0,0')
}

/**
 *
 * @param startedAt
 * @param endedAt
 * @param closeDate
 * @param options
 */
export function getDateRange(
  startedAt: number | null,
  endedAt: number | null,
  closeDate: readonly number[],
  options: {
    startedAtTemplate: string
    endedAtTemplate: string
    closeDateTemplate: string
  }
) {
  if (!startedAt && !endedAt) return ''

  //check is same date
  if (DateTime.formatDate(startedAt) === DateTime.formatDate(endedAt)) {
    return DateTime.formatDate(startedAt, options.startedAtTemplate)
  }

  if (!closeDate?.length) {
    return [
      DateTime.formatDate(startedAt, options.startedAtTemplate),
      //if startedAt not set, use startedAtTemplate for endedAt
      DateTime.formatDate(
        endedAt,
        startedAt ? options.endedAtTemplate : options.startedAtTemplate
      ),
    ].join('〜')
  }

  // remove closeDate out side startedAt and endedAt
  const closeDateValid = closeDate.filter(date => {
    if (startedAt && date < startedAt) {
      return false
    }
    return !(endedAt && date > endedAt)
  })

  // 表示される日付のtimestampを配列しにしたもの
  const ranges = sortBy(closeDateValid)
    .reduce<(number | null)[]>(
      (acc, date) => {
        acc.push(dayjs(date).subtract(1, 'day').valueOf())
        acc.push(dayjs(date).add(1, 'day').valueOf())
        return acc
      },
      [startedAt]
    )
    .filter(date => !closeDateValid.includes(date ?? Infinity))

  // イベントの最後の日付を追加
  if (ranges[ranges.length - 1] !== endedAt) {
    ranges.push(endedAt)
  }

  // これらの日付をフォーマットした文字列で返す
  return ranges
    .reduce<(number | null)[][]>((acc, curr, i, arr) => {
      if (i % 2 === 0 && i < arr.length - 1) {
        acc.push([curr, arr[i + 1]])
      }
      return acc
    }, [])
    .map(([start, end], index) => {
      const rangesChunkLength = Math.ceil(ranges.length / 2)
      const formattedStart = DateTime.formatDate(
        start,
        index === 0 ? options.startedAtTemplate : options.closeDateTemplate
      )
      const formattedEnd = DateTime.formatDate(
        end,
        index === rangesChunkLength - 1
          ? options.endedAtTemplate
          : options.closeDateTemplate
      )
      return DateTime.formatDate(start) === DateTime.formatDate(end)
        ? formattedStart
        : `${formattedStart || ''}〜${formattedEnd || ''}`
    })
    .filter(o => !!o)
    .join('・')
}

export * from './getNewestData'
export * from './vars'
