// @ts-nocheck
import { config } from '@/config'
const { intercomAppId, appInfo } = config
const bundleId = appInfo.android.bundleId || appInfo.ios.bundleId

// 初期化処理
// NOTE: 複数回呼んでもスクリプト取得は初回のみ実施される
export function initialize() {
  if (!(intercomAppId && window)) return
  ;(function () {
    var w = window
    var ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      var d = document
      var i = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      var l = function () {
        var s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/' + intercomAppId
        var x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      l()
    }
  })()

  if (window.Intercom) {
    window.Intercom('boot', {
      app_id: intercomAppId,
    })
  }
}

export function pageIntercom() {
  window.Intercom('update')
}

export function trackIntercom(event, properties) {
  window.Intercom('trackEvent', event, properties)
}

// intercomにお問い合わせするユーザー情報の取得
export function identifyIntercom({
  avatar,
  user_id,
  email,
  name,
  createdAt,
  artist,
}) {
  window.Intercom('update', {
    avatar,
    user_id,
    email,
    name,
    createdAt,
    artist,
    bundle_id: bundleId,
  })
}

export function updateIntercom(user) {
  if (typeof window.Intercom !== 'function') {
    initialize()
  }
  if (user) {
    window.Intercom('update', {
      avatar: user.avatarUrl,
      user_id: user.uid,
      email: user.email,
      name: user.displayName,
    })
  }
}

export function checkIntercomAvailable() {
  return !!window.Intercom
}

export function openIntercom() {
  if (typeof window.Intercom !== 'function') {
    initialize()
  }
  if (window.Intercom) {
    window.Intercom('show')
  }
}
